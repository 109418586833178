import { localizeRouteName } from '@/helpers/route'

export default function users({
  next,
  authUserStore
}) {
  if (authUserStore.getAuth?.Model !== 'User') {
    return next({
      name: localizeRouteName('home')
    })
  }

  return next()
}
