<template lang="pug">
div.separator.space-30(
  v-if="showProfile"
)
.header-profile(
  v-if="showProfile"
  @mouseover="showDropdown = true"
  @mouseleave="showDropdown = false"
)
  .full-name
      | {{ fullName }}

  .avatar
    img(:src="baseUrl + '/storage/avatar/no-avatar.png'")

  .icon
    img(src="@/assets/img/svg/chevron-bottom.svg")

  transition(name="expand")
    .list-containner(v-if="showDropdown")
      ul.options
        li.option.one-row.cursor-pointer(
          @click="toRouteName('settings-personal')"
          v-if="showProfile"
        )
          .icon
            img(src="@/assets/img/svg/settings.svg")
          .title
              | {{ $t('header.settings') }}

        li.option.one-row.cursor-pointer(
          @click="toRouteName('select-impersonation')"
          v-if="showProfile && userIs === 'Operator'"
        )
          .icon
            img(src="@/assets/img/svg/briefcase2.svg")
          .title
              | {{ $t('header.impersonation') }}

        li.option.one-row.cursor-pointer(
          @click="logout"
        )
          .icon
            img(src="@/assets/img/svg/logout.svg")
          .title
              | {{ $t('header.disconnect') }}

</template>

<script>
import { defineComponent, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuthUserStore } from '@/stores'
import { toRouteName } from '@/helpers/route'

export default defineComponent({
  name: 'HeaderProfile',

  setup() {
    const baseUrl = window.location.origin
    const { isAuthenticated, getFullName, getUserType } = storeToRefs(useAuthUserStore())
    const showDropdown = ref(false)

    function logout() {
      const authUserStore = useAuthUserStore()
      authUserStore.logout()
    }

    return {
      baseUrl,
      showProfile: isAuthenticated,
      showDropdown,
      fullName: getFullName,
      logout,
      toRouteName,
      userIs: getUserType
    }
  }
})
</script>
