export async function preloadPinia(context) {
  if (import.meta.env.VITE_DEBUG_MODE === 'false') {
    return
  }

  if (context.store.preload) {
    const storeId = context.store.$id
    console.log('%cPinia preload: ', 'color: orange', storeId)

    await context.store.preload()
  }
}
