<template lang="pug">
carret-right-icon.rotate-90

</template>

<script>
import { defineComponent } from 'vue'
// import IconWrapper from '@/components/icon/IconWrapper.vue'
import CarretRightIcon from '@/components/icon/CarretRightIcon.vue'

export default defineComponent({
  name: 'CarretDownIcon',

  components: {
    CarretRightIcon
    // IconWrapper
  }
})
</script>
