import axios from 'axios'
import { getFullLocale } from '@/helpers/locale'
import { getSessionValue } from '@/helpers/session'

function currentUrl() {
  return window.location.origin
}

const HTTP = axios.create({
  baseURL: `${currentUrl()}/api/`
})

/**
 * Forces endpoint to prepend / at the start and remove / from the end
 * @param endpoint
 * @returns {*}
 */
function fixEndpoint(endpoint) {
  return `/${endpoint.replace(/^\/+|\/+$/g, '')}`
}

function resolveBlobFileName(blob, headers) {
  const header = headers['content-disposition']

  let fileName = null

  if (typeof header === 'string') {
    header.split(';').forEach((headerValue) => {
      const value = headerValue.trim()

      if (value.startsWith('filename=')) {
        fileName = value.slice('filename='.length)
      }
    })
  }

  return fileName
}

function getHeaders() {
  return {
    'Accept-Language': getFullLocale() || undefined,
    'Op-Contragent': getSessionValue('selectedContragent'),
    'Op-User': getSessionValue('selectedImpersonation'),
    'X-With-Taxes': localStorage.getItem('show_price_tax') === '1' ? 'included' : 'not included'
  }
}

export function get(endpoint) {
  return new Promise((resolve, reject) => {
    HTTP.get(fixEndpoint(endpoint), { headers: getHeaders() })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data))
  })
}

export function post(endpoint, payload, config = {}) {
  config.headers = { ...getHeaders(), ...config?.headers || {} }

  return new Promise((resolve, reject) => {
    HTTP.post(fixEndpoint(endpoint), payload, config)
      .then(({ data, headers }) => {
        if (data instanceof Blob) {
          data.fileName = resolveBlobFileName(data, headers)
        }

        resolve(data)
      })
      .catch((error) => reject(error.response.data))
  })
}
