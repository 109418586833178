<template lang="pug">
.notifications
  transition-group(
    name="fade-in-right"
    tag="ul"
  )
    li(
      v-for="notification in notifications"
      :key="notification.id"
    )
      action-notification(:notification="notification")

</template>

<script>
import { defineComponent } from 'vue'
import { useNotificationStore } from '@stores'
import ActionNotification from '@/components/notification/ActionNotification.vue'

export default defineComponent({
  name: 'NotificationList',

  components: {
    ActionNotification
  },

  setup() {
    const { notifications } = useNotificationStore()

    return {
      notifications
    }
  }
})
</script>
