import './assets/main.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from '@/router'

// Plugins
import LayoutHandling from '@/plugins/layoutHandling'
import { preloadPinia } from '@/plugins/piniaPreload'
import { debugPinia } from '@/plugins/piniaDebug'
import Translation from '@/plugins/translation'
import Layouts from '@/plugins/layouts'
import ErrorLogger from '@/plugins/errorLogger'

const pinia = createPinia()
pinia.use(preloadPinia)
pinia.use(debugPinia)

const app = createApp(App)
app.use(router)
app.use(pinia)
app.use(Translation)
app.use(Layouts)
app.use(LayoutHandling)
app.use(ErrorLogger)
app.mount('#app')
