<template lang="pug">
div.switch-wrapper
  div.switch-button
    input(
      type="checkbox"
      id="switch"
      :checked="checked"
      @change="$emit('change', !checked)"
    )

    label(
      for="switch"
    )
      | toggle

  div.label
    span {{ label }}

</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SwitchWithLabel',

  props: {
    label: {
      type: String,
      default: ''
    },

    checked: {
      type: Boolean,
      default: false
    }
  },

  emits: ['change']
})
</script>
