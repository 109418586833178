import { useRoute } from 'vue-router'
import router from '@/router'
import { getLocale, getRouteName } from '@/helpers/locale'
// import { useLanguageStore } from '@/stores'

export function unlocalizeRouteName(routeName) {
  // const languageStore = useLanguageStore()
  // const languageCodes = languageStore.getLanguageCodes.map((code) => code.toLowerCase())
  //
  // if (languageCodes.some((code) => routeName.endsWith(`.${code}`))) {
  //   return routeName.substring(0, routeName.lastIndexOf('.'))
  // }

  return routeName.substring(0, routeName.lastIndexOf('.'))
}

export function localizeRouteName(routeName) {
  return `${routeName}.${getLocale()}`
}

export function toRouteName(name, locale = '', refresh = false, params = {}, query = {}) {
  const routeName = getRouteName(name, locale)

  const routeObject = { name: routeName, params, query }

  console.log('TO ROUTE NAME: ', name)

  router.push(routeObject)
    .then(() => {
      if (refresh) {
        window.location.reload()
      }
    })
}

export function getCurrentRouteName(name) {
  return name.slice(0, -3)
}

export function getCurrentRouteParams() {
  const route = useRoute()

  return route.params
}
