import { useProductStore } from '@stores'
import { post } from '@/plugins/httpService'

export default () => {
  const productStore = useProductStore()

  /**
   *
   * @param productId
   * @param status
   * @param remove
   * @returns {Promise<unknown>}
   */
  function toggleProduct(productId, status, remove = false) {
    return post(`/products/${productId}/${status ? 'favorite' : 'unfavorite'}`)
      .then(({ data }) => productStore.toggleFavoriteProduct(data, remove))
  }

  return {
    toggleProduct
  }
}
