export default {
  getOptions: (state) => (action) => state.options[action],
  getFormData: (state) => (action) => state.forms[action],
  getFormError: (state) => (action, key) => (state.errors[action]?.[key] ? state.errors[action][key][0] : ''),
  getFormInputValue: (state) => (action, path) => {
    let data = state.forms?.[action]

    const segments = path.split('.')

    segments.forEach((key) => {
      data = data?.[key] ?? undefined
    })

    return data
  },
  getFormInputArrayElementValue: (state) => (action, path, criteria) => {
    let data = state.forms?.[action]

    const segments = path.split('.')

    segments.forEach((key) => {
      data = data?.[key] ?? undefined
    })

    if (Array.isArray(data)) {
      data = data.find((value) => Object.keys(criteria).every((key) => value[key] === criteria[key]))
    }

    return data
  },
  hasFormError: (state) => (action, key) => !!state.errors[action][key],
  showErrorNotifRow: (state) => (action, message) => (Object.values(state.errors[action])
    .filter((err) => err.includes(message)).length > 0)
}
