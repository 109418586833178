import { get, post } from '@/plugins/httpService'
import preloader from '@/stores/base/preloader/actions'
import form from '@/composables/form'
import { setUrlParameter } from '@/helpers/url'
import actionTimeoutEnum from '@/enums/actionTimeoutEnum'
import { addToAdvertisement, setNotExpiredAdvertisements, checkIfExist } from '@/helpers/localstorage'

let timeout = null

export default {
  ...preloader,

  preload() {
    // this.fetchList()
  },

  fetchAdverts(type) {
    get(`/advertisements/type/${type}`)
      .then((res) => {
        if (type === 0) {
          this.adverts[0] = res.data
        }
      })
  },

  fetchList() {
    this.isError = false

    get('/advertisements', {
      searchTerm: this.getSearchTerm || undefined,
      page: this.getPage,
      perPage: this.getPerPage
    })
      .then(({ data }) => {
        this.list = data
      })
      .catch(() => {
        this.isError = true
      })
      .finally(() => {
        this.wasFetched = true
      })
  },

  fetchDetails({ slug, id }) {
    post('/advertisements/fetch-single', {
      id,
      slug
    })
      .then((res) => {
        console.log('fetchDetails', res)

        this.setFormDetails(res.data)
      })
  },

  fetchGroupList(type = null) {
    if (type !== null) {
      this.page = 1
    }

    post('/advertisements/fetch-group', {
      type: type ?? this.getListType,
      searchTerm: this.getSearchTerm || undefined,
      page: this.getPage,
      perPage: this.getPerPage
    })
      .then(({ data, meta }) => {
        this.list = data
        this.pages = meta.last_page
        
        if (type !== null) {
          this.listType = type
        }
      })
  },

  setFormDetails(details) {
    const { onInputValue } = form()
    let action = ''

    const type = details?.AdvType

    switch (type) {
      case 0:
        action = 'general-banner-settings'
        break
      case 1:
        action = 'suggested-items-settings'
        break
      case 2:
        action = 'group-pop-up-settings'
        break
      case 3:
        action = 'marked-item-settings'
        break
      default:
        action = ''
    }

    onInputValue(action, ['Advertisement', 'RECID'], details?.RECID)
    onInputValue(action, ['Advertisement', 'Remark'], details?.Remark)
    onInputValue(action, ['Advertisement', 'DisplayFrom'], details?.DisplayFrom)
    onInputValue(action, ['Advertisement', 'DisplayUntil'], details?.DisplayUntil)
    onInputValue(action, ['Advertisement', 'LanguageId'], details?.LanguageId)
    onInputValue(action, ['Advertisement', 'OpenLinkInNewWindow'], details?.OpenLinkInNewWindow)
    onInputValue(action, ['Advertisement', 'OpenLinkOnClick'], details?.OpenLinkOnClick)
    onInputValue(action, ['Advertisement', 'DisplayGroupId'], details?.DisplayGroupId)

    if (action === 'suggested-items-settings' || action === 'marked-item-settings') {
      const item = [
        {
          AXCode: details?.Item?.AXCode,
          Captions: ''
        }
      ]

      onInputValue(action, 'Items', item)
    }
  },

  setPages(pages) {
    this.pages = pages
  },

  setPage(page, fetch = true, changeUrl = true) {
    this.page = page

    if (changeUrl) {
      setUrlParameter('page', this.page)
    }

    if (fetch) {
      this.fetchGroupList()
    }
  },

  setPerPage(perPage) {
    if (![25, 50, 75, 100].includes(perPage)) {
      return
    }

    this.perPage = perPage

    setUrlParameter('per-page', this.perPage)

    this.fetchGroupList()
  },

  setSearchTerm(term, immediate = false) {
    this.searchTerm = term.trim()

    clearTimeout(timeout)

    timeout = setTimeout(() => {
      this.fetchGroupList()

      setUrlParameter('search', this.searchTerm)
    }, !immediate ? actionTimeoutEnum.search : 0)
  },
  
  delete(id) {
    get(`/advertisements/delete/${id}`)
      .then(() => {
        this.fetchGroupList()
      })
  },

  getPopUpList(id) {
    get(`/advertisements/get-by-category/${id}`)
      .then(({ data }) => {
        const localAdv = setNotExpiredAdvertisements()

        if (localAdv.length <= 0) {
          this.adverts[2] = data
          return
        }

        const newData = []

        Object.values(data).forEach((adv) => {
          if (!checkIfExist(adv.id)) {
            newData.push(adv)
          }
        })

        this.adverts[2] = newData
      })
  },

  closeAdv() {
    const { id } = this.adverts[2][0]

    addToAdvertisement(id)

    this.adverts[2] = []
  }
}
