import { getUrlParameter } from '@/helpers/url'

export default () => ({
  list: [],
  isError: false,
  wasFetched: false,
  innerPage: {
    isFetching: false,
    data: null,
    products: {
      list: [],
      isFetching: false
    }
  },
  page: (getUrlParameter('templates-page') ?? 1) * 1,
  pages: 1,
  perPage: getUrlParameter('templates-per-page') ? getUrlParameter('templates-per-page') * 1 : 25,
  searchTerm: getUrlParameter('templates-search') || ''
})
