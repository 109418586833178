<template lang="pug">
.advertisement-pop-up(
  v-if="advId"
)
  .adv-card
    a(
      @click="openLink"
    )
      img(
        :src="`/storage/adv/2/${advId}.png`"
      )

    close-icon(
      @click="closeImg"
    )
</template>

<script>
import { defineComponent } from 'vue'
import CloseIcon from '@icons/closeIcon.vue'
import { storeToRefs } from 'pinia'
import { useAdvertisementStore as useStore } from '@/stores'

export default defineComponent({
  name: 'AdvertisementPopUp',

  components: {
    CloseIcon
  },

  setup() {
    const store = useStore()
    const { getPopUpImageId, getPopUpImageLink, getPopUpImageNewWindow } = storeToRefs(useStore())
    const show = false

    const closeImg = () => {
      store.closeAdv()
    }

    const openLink = () => {
      const link = getPopUpImageLink.value
      const newWindow = getPopUpImageNewWindow.value
      store.closeAdv()

      setTimeout(() => {
        if (newWindow) {
          window.open(link, '_blank')
        } else {
          window.location.href = link
        }
      }, 500)
    }

    return {
      advId: getPopUpImageId,
      show,
      closeImg,
      openLink
    }
  }
})
</script>
