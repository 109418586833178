export default {
  getList: (state) => state.list,
  getIsError: (state) => state.isError,
  getWasFetched: (state) => state.wasFetched,
  getPage: (state) => state.page,
  getPages: (state) => state.pages,
  getPerPage: (state) => {
    const options = [25, 50, 75, 100]

    return options.find((option) => option === state.perPage * 1) || 25
  },
  getSearchTerm: (state) => state.searchTerm,
  getMessageCount: (state) => state.messageCount
}
