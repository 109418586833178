<template lang="pug">
.modal
  .modal-icon
    warning-icon

  .title
    | {{ modal.title }}

  .controls
    secondary-button(
      v-if="modal.buttons?.cancel !== null"
      type="button"
      @click="cancel()"
    )
      | {{ modal.buttons?.cancel || $t('modal.cancel') }}

    primary-button(
      v-if="modal.buttons?.confirm !== null"
      type="button"
      @click="confirm()"
    )
      | {{ modal.buttons?.confirm || $t('modal.confirm') }}

</template>

<script>
import { defineComponent } from 'vue'
import PrimaryButton from '@/components/form/button/PrimaryButton.vue'
import SecondaryButton from '@/components/form/button/SecondaryButton.vue'
import WarningIcon from '@/components/icon/WarningIcon.vue'
import useModal from '@/composables/modal'

export default defineComponent({
  name: 'ModalConfirmation',

  components: {
    WarningIcon,
    SecondaryButton,
    PrimaryButton
  },

  props: {
    modal: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const { hide } = useModal()

    function cancel() {
      hide()
    }

    function confirm() {
      props.modal.onConfirm()

      hide()
    }

    return {
      cancel,
      confirm
    }
  }
})
</script>
