<template lang="pug">
ul.sidebar-menu(
  :data-depth="depth"
)
  template(
    v-for="(item, index) in menu"
    :key="index"
  )
    sidebar-menu-item(
      :category-id="isProducts ? item.id : null"
      :icon="item.icon"
      :title="item.title"
      :route="item.route"
      :show-advert="item.showAdvertisement"
      :has-sub-menu="!!item.subMenu?.length"
      :is-products="isProducts"
      :class="{'is-open': isProducts && item.subMenu?.length && visible(item)}"
    )

      sidebar-sub-menu(
        v-if="(!isProducts && item.subMenu?.length) || (isProducts && item.subMenu?.length && visible(item))"
        :menu="item.subMenu"
        :depth="depth + 1"
        :is-products="isProducts"
      )

</template>

<script>
import { computed, defineComponent } from 'vue'
import { useDisplayGroupStore } from '@stores'
import SidebarMenuItem from '@/components/sidebar/main/menu/SidebarMenuItem.vue'

export default defineComponent({
  name: 'SidebarSubMenu',

  components: {
    SidebarMenuItem
  },

  props: {
    menu: {
      type: Array,
      required: true
    },

    depth: {
      type: Number,
      default: 1
    },

    isProducts: {
      type: Boolean,
      required: false
    }
  },

  setup() {
    const displayGroupStore = useDisplayGroupStore()

    const tree = computed(() => displayGroupStore.getCurrentTreeIds)

    const visible = computed(() => (item) => tree.value.includes(item.id))

    return {
      visible
    }
  }
})
</script>
