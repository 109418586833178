import { ref, computed } from 'vue'
import { get } from '@/plugins/httpService'

export default () => {
  const countries = ref([])

  const getCountries = computed(() => countries)

  function preload() {
    get('/countries')
      .then(({ data }) => {
        countries.value = data
      })
  }

  return {
    getCountries,
    preload
  }
}
