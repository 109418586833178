<template lang="pug">
main-menu

main
  router-view

</template>

<script>
import { defineComponent } from 'vue'
import MainMenu from '@/components/menu/MainMenu.vue'

export default defineComponent({
  name: 'FullwidthLayout',

  components: {
    MainMenu
  }
})
</script>
