export default async function auth({ next, authUserStore, locale, from, to }) {
  let authenticated = false

  if (!authUserStore.isAuthenticated) {
    await authUserStore.authUser()
      .then(() => { authenticated = true })
      .catch(() => { authenticated = false })
  } else {
    authenticated = true
  }

  if (from.name !== `rules.${locale}` && to.name !== `rules.${locale}`) {
    if (authUserStore.isAuthenticated && authUserStore.getUserType === 'User' && authUserStore.getAuth?.showAcceptRules) {
      return next({
        name: `rules.${locale}`
      })
    }
  }

  if (!authUserStore.is2FAValid) {
    return next({
      name: `login-2fa.${locale}`,
      query: { redirect: window.location.pathname }
    })
  }

  if (!authenticated) {
    return next({
      name: `login.${locale}`,
      query: { redirect: window.location.pathname }
    })
  }

  return next()
}
