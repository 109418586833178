<template lang="pug">
div.header-row(
  :class="[$route.meta.layout]"
)
  figure.logo.cursor-pointer(
    @click="toRouteName('home')"
  )
    img(src="@/assets/img/svg/logo.svg")

  company-selector

  language-selector

  header-profile
</template>

<script>
import { defineComponent } from 'vue'
import LanguageSelector from '@/components/global/LanguageSelector.vue'
import CompanySelector from '@/components/global/CompanySelector.vue'
import HeaderProfile from '@/components/global/HeaderProfile.vue'
import { toRouteName } from '@/helpers/route'

export default defineComponent({
  name: 'PageHeader',

  components: {
    HeaderProfile,
    CompanySelector,
    LanguageSelector
  },

  setup() {
    return {
      toRouteName
    }
  }
})
</script>
