const layoutHandling = {
  install(app) {
    app.config.globalProperties.$layout = (key) => this.layoutKey(key)
  },
  
  layoutKey(key) {
    let prefix = 'main'

    if (key !== '') prefix = key

    if (key === '' || typeof key === 'undefined') prefix = 'error'

    return `${prefix}-layout`
  }
}

export default layoutHandling
