import state from './state'
import actions from './actions'
import getters from './getters'

// const extendState = {}
// const extendActions = {}
// const extendGetters = {}
export default {
  state,
  actions,
  getters
}
