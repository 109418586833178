<template lang="pug">
footer
  .left-col
    .logos
      figure
        img(:src="baseUrl + '/storage/logo/dziugas.svg'")

      figure
        img(:src="baseUrl + '/storage/logo/magija.svg'")

      figure
        img(:src="baseUrl + '/storage/logo/germanto.svg'")

      figure
        img(:src="baseUrl + '/storage/logo/saulute.svg'")

      figure
        img(:src="baseUrl + '/storage/logo/rambyno.svg'")

      figure
        img(:src="baseUrl + '/storage/logo/pik-nik.svg'")

  .right-col
    .col
      .row
        p {{ $t('footer.footer_1') }}

      .row
        p {{ $t('footer.footer_2') }}

    .col
      .row
        .icon.m-r-8
          img(src="@/assets/img/svg/phone.svg")

        p {{ $t('footer.footer_3') }}

      .row
        .icon.m-r-8
          img(src="@/assets/img/svg/mail.svg")

        p {{ $t('footer.footer_4') }} b2b@zpienas.lt

    .col
      .row
        .icon.m-r-8
          img(src="@/assets/img/svg/map-pin.svg")

        p {{ $t('footer.footer_5') }}

      .row
        .icon.m-r-8
          img(src="@/assets/img/svg/globe.svg")

        p {{ $t('footer.footer_6') }}

      .row.navus-footer
        span {{ $t('solution') }}
        a(href="https://navus.lt" target="_blank") Navus

mobile-menu(v-if="isMenuOpen")

</template>

<script>
import { useMenuStore } from '@stores'
import { computed, defineComponent } from 'vue'
import MobileMenu from '@/components/menu/mobile/MobileMenu.vue'

export default defineComponent({
  name: 'PageFooter',

  components: {
    MobileMenu
  },

  setup() {
    const menuStore = useMenuStore()

    const baseUrl = window.location.origin

    const isMenuOpen = computed(() => menuStore.isMenuOpen)

    return {
      baseUrl,
      isMenuOpen
    }
  }
})
</script>
