<template lang="pug">
.container
  .empty-page
    router-view
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EmptyLayout'
})
</script>
