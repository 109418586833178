import { useAuthUserStore } from '@stores'
import { storeToRefs } from 'pinia'
import { get, post } from '@/plugins/httpService'
import { toRouteName } from '@/helpers/route'

const RESOURCE = 'rule'

export default {
  async submitRules() {
    const { getAuth } = storeToRefs(useAuthUserStore())
    const authUserStore = useAuthUserStore()
    const payload = {
      RECID: getAuth.value.RECID
    }

    await post(`${RESOURCE}/accept`, payload)
      .then(async () => {
        await authUserStore.authUser()
        toRouteName('home')
      })
  },

  fetchRulePage() {
    get(`${RESOURCE}/rule-page`)
      .then((res) => {
        console.log('success', res)
        this.rulePage = res.data
      })
  }
}
