export default async function valid2Fa({ next, authUserStore, locale }) {
  let authenticated = false

  if (authUserStore.isAuthenticated && authUserStore.is2FAValid) {
    return next({
      name: `select-impersonation.${locale}`,
      query: { redirect: window.location.pathname }
    })
  }

  await authUserStore.authUser()
    .then(() => { authenticated = true })
    .catch(() => { authenticated = false })

  if (authenticated && authUserStore.is2FAValid) {
    return next({
      name: `select-impersonation.${locale}`,
      query: { redirect: window.location.pathname }
    })
  }

  if (!authenticated) {
    return next({
      name: `login.${locale}`,
      query: { redirect: window.location.pathname }
    })
  }

  return next()
}
