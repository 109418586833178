<template lang="pug">
#modals(v-if="modals.length")
  modal-confirmation(
    v-for="modal in modals"
    :modal="modal"
  )

</template>

<script>
import { defineComponent } from 'vue'
import { useModalStore } from '@stores'
import ModalConfirmation from '@/components/modal/confirmation/ModalConfirmation.vue'

export default defineComponent({
  name: 'ModalList',

  components: {
    ModalConfirmation
  },

  setup() {
    const { getModals: modals } = useModalStore()

    return {
      modals
    }
  }
})
</script>
