import { getUrlParameter } from '@/helpers/url'
import localStorageEnum from '@/enums/localStorageEnum'

export default () => ({
  list: [],
  isFetching: false,
  wasFetched: false,
  innerPage: {
    isFetching: false,
    data: null
  },
  filters: {
    specialOffers: false,
    categoryId: null
  },
  contractList: [],
  isFetchingContracts: false,
  wasFetchedContracts: false,
  favoriteList: [],
  isFetchingFavorites: false,
  layout: localStorage.getItem(localStorageEnum.products.layout_key) || 'grid',
  page: (getUrlParameter('page') ?? 1) * 1,
  pages: 1,
  perPage: getUrlParameter('per-page') ? getUrlParameter('per-page') * 1 : 25,
  orderBy: getUrlParameter('order-by') ? getUrlParameter('order-by') : 'price_asc',
  searchTerm: getUrlParameter('search') || '',
  wasJustSearched: false
})
