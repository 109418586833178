import { get, post } from '@/plugins/httpService'
import preloader from '@/stores/base/preloader/actions'
import router from '@/router'
import { localizeRouteName } from '@/helpers/route'
import {useAuthUserStore, useNotificationStore} from '@stores'
import form from '@/composables/form'

const RESOURCE = 'operators'

export default {
  ...preloader,

  fetchList() {
    get(`/${RESOURCE}`)
      .then(({ data }) => {
        this.list = data
        this.isFetched = true
      })
  },

  destroy(RECID) {
    post(`/${RESOURCE}/destroy`, { RECID })
      .then(({ data }) => {
        const index = this.list.findIndex((operator) => operator.RECID === data.RECID)

        if (this.list[index]) {
          this.list.splice(index, 1)
        }
      })
  },

  afterOperatorStore({ data: operator }) {
    router.push({ name: localizeRouteName('settings-operators') })

    this.list.push(operator)
  },

  afterOperatorUpdate({ data: operator }) {
    const index = this.list.findIndex(({ RECID }) => operator.RECID === RECID)

    if (this.list[index]) {
      this.list[index] = operator
    }

    const authUserStore = useAuthUserStore()
    const authUser = authUserStore.getAuth

    if (authUser.Model === 'Operator' && authUser.RECID === operator.RECID) {
      authUserStore.setAuthenticated(operator)
    }

    router.push({ name: localizeRouteName('settings-operators') })
  },

  afterOperatorPasswordChange() {
    form().onInputValue('operator-change-password', ['Operator', 'Password'], '')

    useNotificationStore().push({
      type: 'success',
      title: 'notifications.settings.password_saved',
      translated: false
    })
  },

  afterOperatorPersonalSettingsUpdate({ data }) {
    useAuthUserStore().setAuthenticated(data)

    useNotificationStore().push({
      type: 'success',
      title: 'notifications.settings.changes_saved',
      translated: false
    })
  },

  afterOperatorPersonalPasswordChange() {
    form().onInputValue('operator-change-personal-password', ['Operator', 'Password'], '')

    useNotificationStore().push({
      type: 'success',
      title: 'notifications.settings.password_saved',
      translated: false
    })
  }
}
