<template lang="pug">
icon-wrapper
  img(src="@/assets/img/svg/home.svg")

</template>

<script>
import { defineComponent } from 'vue'
import IconWrapper from '@/components/icon/IconWrapper.vue'

export default defineComponent({
  name: 'HomeIcon',

  components: {
    IconWrapper
  }
})
</script>
