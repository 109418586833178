import { post } from '@/plugins/httpService'
import preloader from '@/stores/base/preloader/actions'
import { setUrlParameter } from '@/helpers/url'
import actionTimeoutEnum from '@/enums/actionTimeoutEnum'
import { download } from '@/helpers/file'

let timeout = null

const RESOURCE = 'invoices'

export default {
  ...preloader,

  preload() {
    // this.fetchList()
  },

  fetchList() {
    this.isError = false

    post(`/${RESOURCE}`, {
      searchTerm: this.getSearchTerm || undefined,
      page: this.getPage,
      perPage: this.getPerPage
    })
      .then(({ data }) => {
        this.list = data
      })
      .catch(() => {
        this.isError = true
      })
      .finally(() => {
        this.wasFetched = true
      })
  },

  setPage(page, fetch = true, changeUrl = true) {
    this.page = page

    if (changeUrl) {
      setUrlParameter('invoices-page', this.page)
    }

    if (fetch) {
      this.fetchList()
    }
  },

  setPages(pages) {
    this.pages = pages
  },

  setPerPage(perPage) {
    if (![25, 50, 75, 100].includes(perPage)) {
      return
    }

    this.perPage = perPage

    setUrlParameter('invoices-per-page', this.perPage)

    this.fetchList()
  },

  setSearchTerm(term, immediate = false) {
    this.searchTerm = term.trim()

    clearTimeout(timeout)

    timeout = setTimeout(() => {
      this.fetchList()

      setUrlParameter('invoices-search', this.searchTerm)
    }, !immediate ? actionTimeoutEnum.search : 0)
  },

  download(RECID) {
    post(`/${RESOURCE}/download`, { RECID }, { responseType: 'blob' })
      .then((blob) => {
        download(blob, blob.fileName)
      })
  }
}
