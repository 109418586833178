import { post } from '@/plugins/httpService'
import * as callback from '@/stores/storeCallbacks'
import FormConfig from '@/config/form.json'
import Logger from '@/plugins/errorLogger'
import { deepClone } from '@/helpers/object'

export default {
  init(action) {
    if (typeof FormConfig.forms[action] === 'undefined') {
      Logger.log([`Form config do not have this action: forms.${action}`], 'error')
      return
    }
    if (typeof FormConfig.options[action] === 'undefined') {
      Logger.log([`Form config do not have this action: options.${action}`], 'error')
      return
    }
    if (typeof FormConfig.errors[action] === 'undefined') {
      Logger.log([`Form config do not have this action: errors.${action}`], 'error')
      return
    }

    // deepClone function removes object references.
    // thus, we avoid writing state inside FormConfig module
    this.forms[action] = deepClone(FormConfig.forms[action])
    this.options[action] = deepClone(FormConfig.options[action])
    this.errors[action] = deepClone(FormConfig.errors[action])
  },

  setInputValue(form, key, value) {
    if (typeof key === 'object') {
      // eslint-disable-next-line array-callback-return
      key.reduce((obj, objectKey, index) => {
        if (index === key.length - 1) {
          obj[objectKey] = value // Set the value for the last key
        } else {
          if (obj.hasOwnProperty(objectKey)) {
            return obj[objectKey]
          }

          return obj
        }
      }, this.forms[form])

      return
    }

    this.forms[form][key] = value
  },

  pushInputValue(form, key, value) {
    const exists = this.forms[form][key].find((element) => Object.keys(element).every((elementKey) => element[elementKey] === value[elementKey]))

    if (!exists) {
      this.forms[form][key].push(value)
    }
  },

  spliceInputValue(form, key, criteria) {
    const index = this.forms[form][key].findIndex((element) => Object.keys(criteria).every((criteriaKey) => element[criteriaKey] === criteria[criteriaKey]))

    if (this.forms[form][key][index]) {
      this.forms[form][key].splice(index, 1)
    }
  },

  resetFormData(action) {
    const options = this.getOptions(action)

    this.init(action)

    this.errors[options.action] = {}

    console.log('form reset', action)
  },

  submit(action) {
    const options = this.getOptions(action)
    const formData = this.getFormData(action)
    let formConfig = {}

    console.log('options',action, options)

    if (options.headers) {
      formConfig = {
        headers: options.headers
      }
    }

    switch (options.method) {
      case 'get':
        //
        break
      case 'post':
        console.log('######## submit form #############: ', action)

        post(options.action, formData, formConfig)
          .then((res) => {
            this.errors[options.action] = {}

            if (action in this.errors) {
              this.errors[action] = {}
            }

            console.log('submit success', res, options.callback)
            callback[options.callback](res)

            if (options?.resetOnSubmit === undefined || options.resetOnSubmit) {
              this.resetFormData(action)
            }
          })
          .catch((err) => {
            console.log('submit error', err)
            this.errors[action] = err.errors
            return err
          })
        break
      default:
        console.error(`Method: "${options.method}" not exists`)
    }
  }
}
