<template lang="pug">
.search-input(:class="{'close-button-visible': showCloseButton}")
  figure.search-icon(@click="focusInput()")
    img(src="@/assets/img/svg/search.svg")

  input(
    ref="input"
    type="text"
    :name="name"
    :value="modelValue"
    :placeholder="placeholder"
    @input="handleInput($event.target.value)"
  )

  button(
    v-if="showCloseButton"
    type="button"
    name="clear-search"
    @click="clear()"
  )
    figure
      img(src="@/assets/img/svg/close.svg")

</template>

<script>
import {computed, defineComponent, ref} from 'vue'

export default defineComponent({
  name: 'SearchInput',

  props: {
    placeholder: {
      type: String
    },

    name: {
      type: String,
      default: 'search'
    },

    modelValue: {
      type: String,
      required: true
    }
  },

  emits: ['input', 'clear'],

  setup(props, { emit }) {
    const input = ref(null)

    const showCloseButton = computed(() => props.modelValue.length)

    function handleInput(value) {
      emit('input', value)
    }

    const clear = () => {
      emit('clear')
    }

    const focusInput = () => {
      input.value.focus()
    }

    return {
      input,
      showCloseButton,
      handleInput,
      clear,
      focusInput
    }
  }
})
</script>
