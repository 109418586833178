import { computed } from 'vue'
import { useContragentStore, usePriceStore } from '@stores'
import { formatPrice, getProductBasePrice, getProductTax } from '@/helpers/price'

export default function price() {
  const priceStore = usePriceStore()

  const contragentStore = useContragentStore()

  const contragent = computed(() => contragentStore.getSelectedItem)

  function getProductPrice(product, quantity, withTaxes, weightPrice = false, withDeposit = true, singlePackageUnitPrice = false) {
    let singleProductPrice = getProductBasePrice(product, weightPrice)

    if (withTaxes || (withTaxes === undefined && priceStore.showPriceWithTaxes.value)) {
      singleProductPrice += getProductTax(product, weightPrice)
    }

    if (withDeposit) {
      singleProductPrice += product.deposit /* product.quantity */
    }

    if (!weightPrice && product.factor > 1) {
      singleProductPrice *= product.factor
    }

    // calculate single product price until this line

    if (!weightPrice && !singlePackageUnitPrice && contragent.value.SellOnlyPackages && product.lowest_quantity > 0) {
      singleProductPrice *= product.lowest_quantity
    }

    return singleProductPrice * quantity
  }

  function getProductDeposit(product) {
    const singleProductDeposit = getSingleProductDeposit(product)

    let packageQuantity = 1

    if (contragent.value.SellOnlyPackages && product.lowest_quantity > 0) {
      packageQuantity = product.lowest_quantity
    }

    return singleProductDeposit * packageQuantity
  }

  function getSingleProductDeposit(product) {
    return product.deposit * 1
  }

  function formatProductPrice(product, quantity, withTaxes, weightPrice = false, withDeposit = true, singlePackageUnitPrice = false) {
    return formatPrice(getProductPrice(product, quantity, withTaxes, weightPrice, withDeposit, singlePackageUnitPrice))
  }

  function formatProductPricePerKg(product) {
    return formatPrice(getProductPrice(product, 1, undefined, true, false))
  }

  return {
    getProductPrice,
    getProductDeposit,
    getSingleProductDeposit,
    formatProductPrice,
    formatProductPricePerKg
  }
}
