import { useCartStore } from '@stores'
import router from '@/router'
import { post, get } from '@/plugins/httpService'
import preloader from '@/stores/base/preloader/actions'
import actionTimeoutEnum from '@/enums/actionTimeoutEnum'
import { localizeRouteName } from '@/helpers/route'
import { setUrlParameter } from '@/helpers/url'
import form from '@/composables/form'

const RESOURCE = 'orders'

let timeout = null

export default {
  ...preloader,

  preload() {
    this.fetchList()
  },

  fetchList() {
    this.isError = false

    post('/orders', {
      searchTerm: this.getSearchTerm || undefined,
      page: this.getPage,
      perPage: this.getPerPage
    })
      .then(({ data }) => {
        this.list = data
      })
      .catch(() => {
        this.isError = true
      })
      .finally(() => {
        this.wasFetched = true
      })
  },

  setPage(page, fetch = true, changeUrl = true) {
    this.page = page

    if (changeUrl) {
      setUrlParameter('orders-page', this.page)
    }

    if (fetch) {
      this.fetchList()
    }
  },

  setPages(pages) {
    this.pages = pages
  },

  setPerPage(perPage) {
    if (![25, 50, 75, 100].includes(perPage)) {
      return
    }

    this.perPage = perPage

    setUrlParameter('orders-per-page', this.perPage)

    this.fetchList()
  },

  setSearchTerm(term, immediate = false) {
    this.searchTerm = term.trim()

    clearTimeout(timeout)

    timeout = setTimeout(() => {
      this.fetchList()

      setUrlParameter('orders-search', this.searchTerm)
    }, !immediate ? actionTimeoutEnum.search : 0)
  },

  setInnerPageData(innerPage) {
    this.innerPage.data = innerPage
  },

  setInnerPageProducts(products) {
    this.innerPage.products.list = products
  },

  fetchInnerPage(id) {
    this.innerPage.isFetching = true

    const product = this.getList.find((item) => item.id === id)
    if (product) {
      this.setInnerPageData(product)
      this.innerPage.isFetching = false
      return
    }

    get(`/${RESOURCE}/${id}`)
      .then(({ data }) => {
        this.setInnerPageData(data)
      })
      .catch(() => {
        this.setInnerPageData(null)
      })
      .finally(() => {
        this.innerPage.isFetching = false
      })
  },

  fetchOrderProducts(orderId) {
    this.innerPage.products.isFetching = true

    get(`/${RESOURCE}/${orderId}/products`)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.setInnerPageProducts(data)
        }
      })
      .finally(() => {
        this.innerPage.products.isFetching = false
      })
  },

  afterOrderSubmit(response) {
    if (!response.data) {
      return
    }

    router.push({ name: localizeRouteName('thank-you') })
      .then(() => {
        form().reset('order')

        useCartStore().fetchCart()

        useCartStore().fetchCartProducts()
      })
  }
}
