import AuthLayout from '@/components/layouts/Auth.vue'
import MainLayout from '@/components/layouts/Main.vue'
import ErrorLayout from '@/components/layouts/Error.vue'
import EmptyLayout from '@/components/layouts/Empty.vue'
import FullwidthLayout from '@/components/layouts/Fullwidth.vue'

export default {
  install(app) {
    app.component('auth-layout', AuthLayout)
    app.component('main-layout', MainLayout)
    app.component('error-layout', ErrorLayout)
    app.component('empty-layout', EmptyLayout)
    app.component('fullwidth-layout', FullwidthLayout)
  }
}
