<template lang="pug">
li(:class="{'has-icon': icon, 'has-products': categoryProductsCount}")
  router-link.menu-item(
    :to="{ ...route, ...{ query: isProducts ? { search: $route?.query?.search || undefined } : {} } }"
    active-class="active"
    @click="clickActions"
  )
    component(
      v-if="icon"
      :is="icon"
    )

    .menu-title
      | {{ title }}

      span.count(v-if="displayCategoryProductsCount")
        | {{ categoryProductsCount }}

    carret-down-icon(v-if="isProducts && hasSubMenu")

  slot

</template>

<script>
import { computed, defineComponent } from 'vue'
import {
  useMenuStore,
  useAdvertisementStore,
  useDisplayGroupStore,
  useProductStore
} from '@/stores'
import CarretDownIcon from '@/components/icon/CarretDownIcon.vue'

export default defineComponent({
  name: 'SidebarMenuItem',

  components: { CarretDownIcon },

  props: {
    icon: {
      type: Object,
      default: null
    },

    title: {
      type: String,
      required: true
    },

    route: {
      type: Object,
      required: true
    },

    showAdvert: {
      type: Boolean,
      default: false
    },

    hasSubMenu: {
      type: Boolean,
      default: false
    },

    isProducts: {
      type: Boolean,
      default: false
    },

    categoryId: {
      type: [Number, null],
      default: null
    }
  },

  // eslint-disable-next-line vue/no-setup-props-destructure
  setup({ showAdvert, route, isProducts, categoryId }) {
    const menuStore = useMenuStore()
    const advertisementStore = useAdvertisementStore()
    const displayGroupStore = useDisplayGroupStore()
    const productStore = useProductStore()

    const categoryProductsCount = computed(() => {
      if (!isProducts) {
        return null
      }

      return (displayGroupStore.getCategoriesProductsCount?.[categoryId] || 0) * 1
    })

    const displayCategoryProductsCount = computed(() => {
      return productStore.getSearchTerm.length && categoryProductsCount.value
    })

    function closeMobileMenu() {
      if (window.innerWidth <= 768) {
        menuStore.close()
      }
    }

    const showAdvertisement = () => {
      if (showAdvert) {
        const category = route.params.category1
        advertisementStore.getPopUpList(category)
      }
    }

    const clickActions = () => {
      closeMobileMenu()
      showAdvertisement()
    }

    return {
      clickActions,
      categoryProductsCount,
      displayCategoryProductsCount
    }
  }
})
</script>
