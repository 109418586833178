import { computed, ref } from 'vue'

export default () => {
  const menuOpen = ref(null)

  const isMenuOpen = computed(() => menuOpen.value !== null)
  const getMenuOpen = computed(() => menuOpen)

  function open(menu) {
    if (!['languages', 'users', 'companies', 'products', 'settings'].includes(menu)) {
      return
    }

    menuOpen.value = menu
  }

  function close() {
    menuOpen.value = null
  }

  return {
    isMenuOpen,
    getMenuOpen,
    open,
    close
  }
}
