<template lang="pug">
.mobile-menu-container
  header
    button(
      type="button"
      name="back"
      @click="back()"
    )
      carret-left-icon

    .title
      | {{ $t(title) }}

    slot(name="header")

  slot(name="default")

</template>

<script>
import { defineComponent } from 'vue'
import CarretLeftIcon from '@icons/CarretLeftIcon.vue'

export default defineComponent({
  name: 'MobileMenuContainer',

  components: {
    CarretLeftIcon
  },

  props: {
    title: {
      type: String,
      default: ''
    }
  },

  setup(props, { emit }) {
    function back() {
      emit('close')
    }

    return {
      back
    }
  }
})
</script>
