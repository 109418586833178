import errorLogger from '@/plugins/errorLogger'

export function getSessionValue(key) {
  const value = sessionStorage.getItem(key)

  return !Number.isNaN(value) ? value * 1 : value
}

export function resetSesionData() {
  sessionStorage.removeItem('selectedImpersonation')
  sessionStorage.removeItem('selectedContragent')
}

export function logSesionData() {
  const selectedContragent = sessionStorage.getItem('selectedContragent')
  const selectedImpersonation = sessionStorage.getItem('selectedImpersonation')

  errorLogger.log(['%c<<<< SESION DATA START >>>>', 'color: #09acb1'], 'log')
  errorLogger.log([{ selectedContragent, selectedImpersonation }], 'table')
  errorLogger.log(['%c<<<< SESION DATA END >>>>', 'color: #09acb1'], 'log')
}
