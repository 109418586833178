<template lang="pug">
mobile-menu-options(
  :options="contragents"
  :active-key="currentContragentIndex"
  @click="handleClick($event)"
)
  template(v-slot="{ CompanyName }")
    img(src="@/assets/img/svg/briefcase-light.svg")

    | {{ CompanyName }}

</template>

<script>
import { defineComponent, computed } from 'vue'
import { useContragentStore, useMenuStore } from '@stores'
import MobileMenuOptions from '@/components/menu/mobile/MobileMenuOptions.vue'

export default defineComponent({
  name: 'ContragentMenu',

  components: {
    MobileMenuOptions
  },

  setup() {
    const contragentStore = useContragentStore()

    const menuStore = useMenuStore()

    const contragents = computed(() => contragentStore.getList)

    const selectedContragent = computed(() => contragentStore.getSelectedItem)

    const currentContragentIndex = computed(() => contragents.value.findIndex((contragent) => contragent.RECID === selectedContragent.value.RECID))

    function handleClick(contragent) {
      contragentStore.setSelectedItem(contragent)

      menuStore.close()
    }

    return {
      contragents,
      currentContragentIndex,
      handleClick
    }
  }
})
</script>
