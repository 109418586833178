export function deepClone(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  if (Array.isArray(obj)) {
    const newArray = []

    for (let i = 0; i < obj.length; i++) {
      newArray[i] = deepClone(obj[i])
    }

    return newArray
  }

  if (typeof obj === 'object') {
    const newObj = {}

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[key] = deepClone(obj[key])
      }
    }
    return newObj
  }

  return obj
}
