<template lang="pug">
aside#main-sidebar
  template(v-if="showMenu === 'products'")
    search-input

    products-menu

  template(v-if="showMenu === 'settings'")
    settings-menu

</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import SearchInput from '@/components/product/filter/ProductSearchInput.vue'
import ProductsMenu from '@/components/menu/ProductsMenu.vue'
import SettingsMenu from '@/components/menu/SettingsMenu.vue'

export default defineComponent({
  name: 'MainSidebar',

  components: {
    SettingsMenu,
    SearchInput,
    ProductsMenu
  },

  setup() {
    const route = useRoute()
    const showMenu = ref(route.meta.menu)

    watch(route, (newRoute) => {
      const routeMenu = newRoute.meta.menu

      if (routeMenu) {
        showMenu.value = routeMenu
      }
    })

    return {
      showMenu
    }
  }
})
</script>
