<template lang="pug">
.button-wrapper(
  :class="[dynamicWidth ? 'dynamic-width' : 'full-width ', small ? 'btn-small': '']"
)
  button.btn.secondary(
    :type="type"
    :disabled="disabled"
  )
    slot

</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SecondaryButton',

  props: {
    type: {
      type: String,
      default: 'button'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    dynamicWidth: {
      type: Boolean,
      default: false
    },

    small: {
      type: Boolean,
      default: false
    }
  }
})
</script>
