import config from './config.json'
import routes from './routes'
import guest from '@/router/middlewares/guest'
import { locales, mainLocale } from '@/helpers/locale'

const translatedRoutes = []

routes.forEach((route) => {
  locales.forEach((lang) => {
    const transRoutePath = config.routes[lang][route.path]
    const routePath = lang === mainLocale ? transRoutePath : `/${lang}${transRoutePath}`

    const routeObject = {
      path: routePath,
      name: `${route.name}.${lang}`,
      component: route.component,
      meta: route.meta
    }

    if (route.redirect) {
      const transRedirectPath = config.routes[lang][route.redirect]
      routeObject.redirect = lang === mainLocale ? transRedirectPath : `/${lang}${transRedirectPath}`
    }

    translatedRoutes.push(routeObject)
  })
})

export default [
  ...translatedRoutes,
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/view/error/404View.vue'),
    meta: {
      layout: 'error',
      middleware: [
        guest
      ]
    }
  }
]
