import * as store from '@/stores'
import { toRouteName, localizeRouteName } from '@/helpers/route'

export function afterLogin(res) {
  const authUserStore = store.useAuthUserStore()

  authUserStore.afterLogin(res)
}

export function afterRegister(res) {
  const authUserStore = store.useAuthUserStore()

  authUserStore.afterRegister(res)
}

export function after2FA(res) {
  const authUserStore = store.useAuthUserStore()

  authUserStore.after2FA(res)
}

export function afterTemplateUpdate(response) {
  store.useTemplateStore().afterTemplateUpdate(response)
}

export function afterSubmitOrder(response) {
  store.useOrderStore().afterOrderSubmit(response)
}

export function afterUserSettingsUpdate(response) {
  store.useAuthUserStore().afterUserUpdate(response)
}

export function afterOperatorSettingsStore(response) {
  store.useOperatorStore().afterOperatorStore(response)
}

export function afterOperatorSettingsUpdate(response) {
  store.useOperatorStore().afterOperatorUpdate(response)
}

export function afterUserPasswordChange(response) {
  store.useAuthUserStore().afterUserPasswordChange(response)
}

export function afterOperatorPasswordChange(response) {
  store.useOperatorStore().afterOperatorPasswordChange(response)
}

export function afterContragentUpdate(response) {
  store.useContragentStore().afterContragentUpdate(response)
}

export function afterContragentStore(response) {
  store.useContragentStore().afterContragentStore(response)
}

export function afterContragentAddressStore(response) {
  store.useContragentStore().afterContragentAddressStore(response)
}

export function afterContragentAddressUpdate(response) {
  store.useContragentStore().afterContragentAddressUpdate(response)
}

export function afterCreateGeneral() {
  toRouteName('settings-advertisement-general-banner')
}

export function afterCreateGroup() {
  toRouteName('settings-advertisement-group-pop-up')
}

export function afterCreateSuggested() {
  toRouteName('settings-advertisement-suggested-items')
}

export function afterCreateMarked() {
  toRouteName('settings-advertisement-marked-item')
}

export function afterEmployeeStore(response) {
  store.useUserStore().afterEmployeeStore(response)
}

export function afterEmployeeUpdate(response) {
  store.useUserStore().afterEmployeeUpdate(response)
}

export function afterEmployeePasswordChange(response) {
  store.useUserStore().afterEmployeePasswordChange(response)
}

export function afterOperatorPersonalSettingsUpdate(response) {
  store.useOperatorStore().afterOperatorPersonalSettingsUpdate(response)
}

export function afterOperatorPersonalPasswordChange(response) {
  store.useOperatorStore().afterOperatorPersonalPasswordChange(response)
}

export const afterRemindPassword = () => store.useAuthUserStore().afterPasswordLinkSend()

export const afterPasswordReset = () => { toRouteName('forgot-password-success') }
