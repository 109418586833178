export function download(blob, fileName) {
  const url = window.URL.createObjectURL(blob)

  const anchor = document.createElement('a')
  anchor.setAttribute('href', url)
  anchor.setAttribute('download', fileName)
  anchor.setAttribute('style', 'display:none;')

  document.body.appendChild(anchor)
  anchor.click()

  document.body.removeChild(anchor)
  window.URL.revokeObjectURL(url)
}
