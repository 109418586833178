<template lang="pug">
.page-menu
  nav
    ul
      li(
        v-for="(item, index) in menu"
        :key="index"
        :class="[item.to]"
      )
        router-link(:to="{name: localizeRouteName(item.to)}").menu-item
          .icon-container
            component(
              v-if="icons?.[item.to]"
              :is="icons[item.to]"
            )

            .records(v-if="0 < item.records")
              | {{ item.records }}

          .menu-title
            | {{ $t(item.title) }}

      li.tax-toggle-item
        .menu-item
          tax-toggler

      li.burger-item
        button.menu-item(
          type="button"
          name="menu"
          @click="openMenu()"
        )
          figure.icon-wrapper
            img.light(src="@/assets/img/svg/menu-light.svg")

          .menu-title
            | {{ $t('menu.menu') }}

</template>

<script>
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useMenuStore, useCartStore, useMessageStore } from '@stores'
import CartIcon from '@icons/CartIcon.vue'
import ListIcon from '@icons/ListIcon.vue'
import DocumentIcon from '@icons/DocumentIcon.vue'
import BellIcon from '@icons/BellIcon.vue'
import { localizeRouteName } from '@/helpers/route'
import TaxToggler from '@/components/global/TaxToggler.vue'

export default defineComponent({
  name: 'MainMenu',

  components: {
    TaxToggler
  },

  setup() {
    const menuStore = useMenuStore()
    const cartStore = useCartStore()
    const messageStore = useMessageStore()
    const route = useRoute()

    const icons = {
      cart: CartIcon,
      orders: ListIcon,
      invoices: DocumentIcon,
      messages: BellIcon
    }

    const menu = computed(() => [
      {
        title: 'menu.cart',
        to: 'cart',
        records: cartStore.getCartProductsCount
      },

      {
        title: 'menu.orders',
        to: 'orders',
        records: 0
      },

      {
        title: 'menu.invoices',
        to: 'invoices',
        records: 0
      },

      {
        title: 'menu.messages',
        to: 'messages',
        records: messageStore.getMessageCount
      }
    ])

    function openMenu() {
      menuStore.open(route.meta.menu)
    }

    return {
      icons,
      menu,
      localizeRouteName,
      openMenu
    }
  }
})
</script>
