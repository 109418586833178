import { createI18n } from 'vue-i18n'
import lt from '@/transltions/lt.json'
import en from '@/transltions/en.json'
import { getLocale, locales, mainLocale } from '@/helpers/locale'

export default {
  install(app) {
    app.use(createI18n({
      locale: getLocale(),
      locales,
      fallbackLocale: mainLocale,
      legacy: false,
      messages: {
        lt,
        en
      }
    }))
  }
}
