<template lang="pug">
switch-with-label(
  :label="$t(pricesWithTaxes ? 'with_taxes' : 'without_taxes')"
  :checked="pricesWithTaxes"
  @change="togglePriceDisplay"
)

</template>

<script>
import { defineComponent } from 'vue'
import { usePriceStore } from '@stores'
import SwitchWithLabel from '@/components/form/switch/SwitchWithLabel.vue'

export default defineComponent({
  name: 'TaxToggler',

  components: {
    SwitchWithLabel
  },

  setup() {
    const priceStore = usePriceStore()

    const pricesWithTaxes = priceStore.showPriceWithTaxes

    const togglePriceDisplay = (isChecked) => {
      priceStore.setPricesWithTaxes(isChecked)
    }

    return {
      pricesWithTaxes,
      togglePriceDisplay
    }
  }
})
</script>
