import { computed } from 'vue'
import { useFormStore } from '@/stores'

export default function form() {
  const formStore = useFormStore()

  const isFormInitiated = computed(() => (action) => !!formStore.getFormData(action))

  function initForm(action) {
    formStore.init(action)
  }

  function onInputValue(action, key, newVal) {
    formStore.setInputValue(action, key, newVal)
  }

  function getInputValue(action, key) {
    return formStore.getFormInputValue(action, key)
  }

  function submitForm(action) {
    formStore.submit(action)
  }

  function getError(action, key) {
    const errorKey = formStore.getFormError(action, key)

    const validationKey = key.split('.')
    let validKey = validationKey[0]

    if (validationKey.length > 1) {
      validKey = validationKey[validationKey.length - 1]
    }

    return errorKey ? this.$t(`validation.${validKey}.${errorKey}`) : ''
  }

  function showNotificationRow(action, message) {
    return formStore.showErrorNotifRow(action, message)
  }

  function reset(formName) {
    formStore.resetFormData(formName)
  }

  return {
    isFormInitiated,
    onInputValue,
    getInputValue,
    submitForm,
    getError,
    showNotificationRow,
    initForm,
    reset
  }
}
