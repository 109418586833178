<template lang="pug">
page-header

.page-content
  component(:is="$layout($route.meta.layout)")

page-footer

modal-list

notification-list

</template>

<script>
import { defineComponent } from 'vue'
import PageHeader from '@/components/global/PageHeader.vue'
import PageFooter from '@/components/global/PageFooter.vue'
import NotificationList from '@/components/notification/NotificationList.vue'
import ModalList from '@/components/modal/ModalList.vue'

export default defineComponent({
  name: 'MainApp',

  components: {
    ModalList,
    NotificationList,
    PageFooter,
    PageHeader
  }
})
</script>
