import { get } from '@/plugins/httpService'

export default {
  async preload() {
    await this.fetchList()
  },

  async fetchList() {
    await get('/language/list')
      .then((res) => {
        this.list = res.data
      })
  }
}
