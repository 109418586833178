<template lang="pug">
.empty-space(
  v-if="!selectedContrigent"
)

.company-selector(
  v-if="isAuthenticated && selectedContrigent"
  @mouseover="handleMousemove(true)"
  @mouseleave="handleMousemove(false)"
  @click="handleClick()"
)
  .icon
    img(src="@/assets/img/svg/briefcase.svg")
  .company-title
    | {{ selectedContrigent?.CompanyName }}
    .icon
      img(src="@/assets/img/svg/chevron-bottom.svg")

  transition(name="expand")
    .list-containner(v-if="showDropdown")
      ul.options
        li.option(
          v-for="contagent in contragents"
          @click="selectItem(contagent)"
        )
          | {{ contagent?.CompanyName }}

.empty-space(v-if="isAuthenticated")
</template>

<script>
import { defineComponent, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuthUserStore , useContragentStore } from '@/stores'
import menuControl from '@/composables/menuControl.js'

export default defineComponent({
  name: 'CompanySelector',

  setup() {
    const { isAuthenticated } = storeToRefs(useAuthUserStore())
    const { getList, getSelectedItem } = storeToRefs(useContragentStore())
    const contragentStore = useContragentStore()
    const showDropdown = ref(false)

    function selectItem(item) {
      showDropdown.value = false
      contragentStore.setSelectedItem(item)
    }

    const { handleMousemove, handleClick } = menuControl(showDropdown, 'companies')

    return {
      isAuthenticated,
      contragents: getList,
      selectedContrigent: getSelectedItem,
      showDropdown,
      selectItem,
      handleMousemove,
      handleClick
    }
  }
})
</script>
