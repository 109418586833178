export default {
  install(app) {
    app.config.globalProperties.$log = (messages = [], type = 'log') => this.log(messages, type)
  },

  log(messages = [], type = 'log') {
    if (!import.meta.env.VITE_DEBUG_MODE) {
      return
    }

    switch (type) {
      case 'log':
        console.log(...messages)
        break
      case 'error':
        console.error(...messages)
        break
      case 'info':
        console.info(...messages)
        break
      case 'warn':
        console.warn(...messages)
        break
      case 'table':
        console.table(...messages)
        break
      default:
        console.log(...messages)
        break
    }
  }
}
