import { formatPrice, getProductBasePrice, getProductTax } from '@/helpers/price'
import priceComposable from '@/composables/price'
import {useContragentStore} from '@stores'

export default {
  getList: (state) => state.list,
  getIsError: (state) => state.isError,
  getWasFetched: (state) => state.wasFetched,
  getPage: (state) => state.page,
  getPages: (state) => state.pages,
  getPerPage: (state) => {
    const options = [25, 50, 75, 100]

    return options.find((option) => option === state.perPage * 1) || 25
  },
  getSearchTerm: (state) => state.searchTerm,
  getProducts: (state) => state.innerPage.products.list,
  getProductsIsFetching: (state) => state.innerPage.products.isFetching,
  getInnerPageData: (state) => state.innerPage.data,
  isInnerPageFetching: (state) => state.innerPage.isFetching,
  getSummaryWeight: (state) => {
    const contragentStore = useContragentStore()

    const weight = state.innerPage.products.list.reduce((accumulator, product) => {
      const packageQuantity = (contragentStore.canSellOnlyPackages ? product.lowest_quantity : 1) || 1

      return accumulator + (product.weight_in_kg * packageQuantity * product.quantity)
    }, 0)

    const roundedWeight = parseFloat(weight).toFixed(2).replace(/\.?0*$/, '')

    return `${roundedWeight.toString().replace('.', ',')} kg`
  },
  getSummaryQuantity: (state) => {
    const sum = state.innerPage.products.list.reduce((accumulator, product) => {
      return accumulator + product.quantity
    }, 0)

    return `${sum} vnt`
  },
  getSummaryPriceWithoutTax: (state) => {
    const contragentStore = useContragentStore()

    const taxlessPrice = state.innerPage.products.list.reduce((accumulator, product) => {
      const basePrice = getProductBasePrice(product)

      const packageQuantity = (contragentStore.canSellOnlyPackages ? product.lowest_quantity : 1) || 1

      const factor = product.factor || 1

      return accumulator + basePrice * factor * packageQuantity * product.quantity
    }, 0)

    return formatPrice(taxlessPrice)
  },
  getSummaryTax: (state) => {
    const contragentStore = useContragentStore()

    const taxPrice = state.innerPage.products.list.reduce((accummulator, product) => {
      const tax = getProductTax(product)

      const packageQuantity = (contragentStore.canSellOnlyPackages ? product.lowest_quantity : 1) || 1

      const factor = product.factor || 1

      return accummulator + tax * factor * packageQuantity * product.quantity
    }, 0)

    return formatPrice(taxPrice)
  },
  getSummaryTotalPrice: (state) => {
    const { getProductPrice } = priceComposable()

    const price = state.innerPage.products.list.reduce((accumulator, product) => {
      return accumulator + getProductPrice(product, product.quantity, true)
    }, 0)

    return formatPrice(price)
  },
  getSummaryDeposit: (state) => {
    const { getProductDeposit } = priceComposable()

    const deposit = state.innerPage.products.list.reduce((accumulator, product) => {
      return accumulator + (getProductDeposit(product) * product.quantity)
    }, 0)

    return formatPrice(deposit)
  },
  summaryHasMeasuredProduct: (state) => !!state.innerPage.products.list.find((product) => product.is_measured)
}
