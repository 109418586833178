export function roundPrice(price) {
  const rounded = Math.round(parseFloat(price) * 100) / 100

  return Math.trunc(rounded * 100) / 100
}

export function formatPrice(price, currencyChar = '€') {
  return `${roundPrice(price).toFixed(2).replace('.', ',')} ${currencyChar}`
}

export function getProductBasePrice(product, weightPrice = false) {
  if (weightPrice) {
    return product.price_per_kg * 1
  }

  return product.base_price * 1
  // return (product.contract_price ?? product.base_price) * 1
}

export function getProductTax(product, weightPrice = false) {
  const vatPercent = product.BasePriceVATPerc * 1

  if (vatPercent <= 0) {
    return 0
  }

  return getProductBasePrice(product, weightPrice) * (vatPercent / 100)
}
