export default function contragent({
  next,
  authUserStore,
  locale,
  contragentStore,
  from,
  to
}) {
  const selectedContragent = sessionStorage.getItem('selectedContragent')
  const selectedImpersonation = sessionStorage.getItem('selectedImpersonation')

  if (from.name !== `rules.${locale}` && to.name !== `rules.${locale}`) {
    if (authUserStore.isAuthenticated && authUserStore.getUserType === 'User' && authUserStore.getAuth?.showAcceptRules) {
      return next({
        name: `rules.${locale}`
      })
    }
  }

  if (authUserStore.getUserType === 'Operator' && contragentStore.getSelectedItem === null) {
    if (selectedContragent !== 'undefined' && selectedContragent && typeof selectedImpersonation !== 'undefined' && selectedImpersonation) {
      contragentStore.fetchSelectedItems(selectedImpersonation, selectedContragent)
      return next()
    }

    return next({
      name: `select-impersonation.${locale}`
    })
  }

  if (authUserStore.getUserType === 'User' && contragentStore.getSelectedItem === null) {
    const user = authUserStore.getAuth
    contragentStore.fetchSelectedItems(user.RECID, (selectedContragent !== 'undefined' && selectedContragent) ? selectedContragent : null)

    return next()
  }

  if (!authUserStore.isAuthenticated) {
    return next({
      name: `login.${locale}`,
      query: { redirect: window.location.pathname }
    })
  }

  return next()
}
