<template lang="pug">
main-menu

advertisement-pop-up

.container
  main-sidebar

  main
    router-view

</template>

<script>
import { defineComponent } from 'vue'
import MainMenu from '@/components/menu/MainMenu.vue'
import MainSidebar from '@/components/sidebar/main/MainSidebar.vue'
import AdvertisementPopUp from '@/components/global/AdvertisementPopUp.vue'

export default defineComponent({
  name: 'MainLayout',

  components: {
    AdvertisementPopUp,
    MainSidebar,
    MainMenu
  },

  setup() {
    return {
      isMobile: window.innerWidth <= 768
    }
  }
})
</script>
