import { get, post } from '@/plugins/httpService'
import preloader from '@/stores/base/preloader/actions'
import actionTimeoutEnum from '@/enums/actionTimeoutEnum'
import form from '@/composables/form'
import router from '@/router'
import {localizeRouteName} from '@/helpers/route.js'

const RESOURCE = 'cart'

let handleProductQuantityUpdateTimeout = null

export default {
  ...preloader,

  preload() {
    this.fetchCartProducts()
  },

  fetchCart() {
    this.isFetching = true

    get(`/${RESOURCE}`, {})
      .then(({ data }) => {
        this.cart = data
        this.wasFetched = true
      })
      .finally(() => {
        this.isFetching = false
      })
  },

  updateCart(formAction, key, value) {
    this.isUpdating = true

    form().onInputValue(formAction, key, value)

    post(`/${RESOURCE}/update`, {
      [key[0]]: {
        [key[1]]: value
      }
    })
      .then(({ data }) => {
        this.cart = data
      })
      .finally(() => {
        this.isUpdating = false
      })
  },

  fetchCartProducts() {
    this.products.isFetching = true

    get(`/${RESOURCE}/products`, {})
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.products.list = data
        }
      })
      .finally(() => {
        this.products.isFetching = false
      })
  },

  pushProduct(product) {
    this.products.list.push(product)
  },

  updateProduct(product, index) {
    if (this.products.list[index]) {
      this.products.list[index] = product
    }
  },

  pushOrUpdateProduct(product) {
    const index = this.products.list.findIndex(({ id }) => id === product.id)

    if (index >= 0) {
      this.updateProduct(product, index)
    } else {
      this.pushProduct(product)
    }

    return product
  },

  addProduct({ ItemId, Quantity }) {
    return post(`/${RESOURCE}/products/store`, { ItemId, Quantity })
      .then(({ data }) => this.pushOrUpdateProduct(data))
  },

  removeProduct(productId) {
    post(`/${RESOURCE}/products/destroy`, { productId })
      .then(({ data }) => {
        const index = this.getProducts.findIndex((item) => item.id === data.id)

        if (index >= 0) {
          this.products.list.splice(index, 1)
        }
      })
  },

  cartTemplateProducts(template) {
    post(`/${RESOURCE}/templates/products/add-to-cart`, { TemplateId: template.RECID })
      .then(({ data }) => {
        data.forEach((product) => this.pushOrUpdateProduct(product))

        router.push({ name: localizeRouteName('cart') })
      })
  },

  handleProductQuantityUpdate(product, quantity) {
    clearTimeout(handleProductQuantityUpdateTimeout)

    handleProductQuantityUpdateTimeout = setTimeout(() => {
      if (quantity === 0) {
        this.removeProduct(product.id)
        return
      }

      post(`/${RESOURCE}/products/update`, { ItemId: product.id, Quantity: quantity })
        .then(({ data }) => this.pushOrUpdateProduct(data))
    }, actionTimeoutEnum.product.quantity)
  }
}
