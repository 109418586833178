import { computed, ref } from 'vue'

export default () => {
  const modals = ref([])

  const getModals = computed(() => modals)

  function show(modal) {
    modals.value.push(modal)
  }

  function hide() {
    modals.value = []
  }

  return {
    modals,
    getModals,
    show,
    hide
  }
}
