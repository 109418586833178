import { useMenuStore } from '@stores'

export default function menuControl(menuVisibilityControl, menuName) {
  const menuStore = useMenuStore()

  function handleMousemove(isMouseOver) {
    if (window.innerWidth <= 768) {
      return
    }

    menuVisibilityControl.value = !!isMouseOver
  }

  function handleClick() {
    if (window.innerWidth > 768) {
      return
    }

    menuStore.open(menuName)
  }

  return {
    handleMousemove,
    handleClick
  }
}
