import preloader from '@/stores/base/preloader/actions'
import { get, post } from '@/plugins/httpService'
import { useContragentStore } from '@/stores'
import { setUrlParameter } from '@/helpers/url'
import actionTimeoutEnum from '@/enums/actionTimeoutEnum'

let timeout = null

export default {
  ...preloader,

  preload() {
    this.fetchList()
  },

  fetchList() {
    post('impersonations/all', {
      searchTerm: this.getSearchTerm || undefined,
      page: this.getPage,
      perPage: this.getPerPage
    })
      .then((res) => {
        this.list = res.data.data
        this.pages = res.data.last_page
      })
  },

  async setSelectedItem(impersonationId, contragentId = null) {
    this.selectedItem = impersonationId

    return post('/contragents/all', {
      User: {
        RECID: impersonationId
      }
    })
      .then((res) => {
        const contragentStore = useContragentStore()
        contragentStore.setContragentList(res.data)

        if (contragentId) {
          const selected = res.data.find((el) => el.RECID === parseInt(contragentId, 10))
          contragentStore.setSelectedItem(selected)
        } else {
          contragentStore.setSelectedItem(res.data[0])
        }

        sessionStorage.setItem('selectedImpersonation', impersonationId)

        return res
      })
  },

  setPages(pages) {
    this.pages = pages
  },

  setPage(page, fetch = true, changeUrl = true) {
    this.page = page

    if (changeUrl) {
      setUrlParameter('page', this.page)
    }

    if (fetch) {
      this.fetchList()
    }
  },

  setPerPage(perPage) {
    if (![25, 50, 75, 100].includes(perPage)) {
      return
    }

    this.perPage = perPage

    setUrlParameter('per-page', this.perPage)

    this.fetchList()
  },

  setSearchTerm(term, immediate = false) {
    this.searchTerm = term.trim()

    clearTimeout(timeout)

    timeout = setTimeout(() => {
      this.fetchList()

      setUrlParameter('search', this.searchTerm)
    }, !immediate ? actionTimeoutEnum.search : 0)
  }
}
