import { get, post } from '@/plugins/httpService'
import preloader from '@/stores/base/preloader/actions'
import { setUrlParameter } from '@/helpers/url'
import actionTimeoutEnum from '@/enums/actionTimeoutEnum'

const RESOURCE = 'message'
let timeout = null

export default {
  ...preloader,

  preload() {
    this.setNewMsgCount()
  },

  fetchList() {
    this.isError = false

    post(`/${RESOURCE}/`, {
      searchTerm: this.getSearchTerm || undefined,
      page: this.getPage,
      perPage: this.getPerPage
    })
      .then(({ data, meta }) => {
        this.list = data
        this.pages = meta.last_page
      })
      .catch(() => {
        this.isError = true
      })
      .finally(() => {
        this.wasFetched = true
      })

    this.setNewMsgCount()
  },

  setNewMsgCount() {
    get(`/${RESOURCE}/new-msg-count`)
      .then((res) => {
        this.messageCount = res.data
      })
  },

  getSingleMessage(id) {
    return get(`message/get/${id}`)
      .then((res) => {
        this.setNewMsgCount()
        return res.data.original.data
      })
  },

  setPages(pages) {
    this.pages = pages
  },

  setPage(page, fetch = true, changeUrl = true) {
    this.page = page

    if (changeUrl) {
      setUrlParameter('page', this.page)
    }

    if (fetch) {
      this.fetchList()
    }
  },

  setPerPage(perPage) {
    if (![25, 50, 75, 100].includes(perPage)) {
      return
    }

    this.perPage = perPage

    setUrlParameter('per-page', this.perPage)

    this.fetchList()
  },

  setSearchTerm(term, immediate = false) {
    this.searchTerm = term.trim()

    clearTimeout(timeout)

    timeout = setTimeout(() => {
      this.fetchList()

      setUrlParameter('search', this.searchTerm)
    }, !immediate ? actionTimeoutEnum.search : 0)
  },

  deleteMessage(id) {
    return get(`message/delete/${id}`)
      .then(() => this.fetchList())
  }
}
