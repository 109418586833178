import { createRouter, createWebHistory } from 'vue-router'
import routes from './routeBuilder'
import middlewarePipeline from '@/router/middlewarePipeline'
import { useAuthUserStore, useContragentStore, useHistoryStore } from '@/stores'
import { getLocale } from '@/helpers/locale'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  }
})

function getNonceFromScriptTag() {
  const scripts = document.querySelectorAll('script[type="module"]')
  // eslint-disable-next-line no-restricted-syntax
  for (const script of scripts) {
    if (script.hasAttribute('nonce')) {

      console.log(script.getAttribute('nonce'))

      return script.getAttribute('nonce')
    }
  }
  return null
}

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }

  const { middleware } = to.meta
  const authUserStore = useAuthUserStore()
  const contragentStore = useContragentStore()

  const context = {
    to,
    from,
    next,
    authUserStore,
    contragentStore,
    locale: getLocale()
  }

  const nonce = getNonceFromScriptTag()

  // Modify the <link> tags
  const links = document.querySelectorAll('link[rel="modulepreload"]')
  links.forEach((link) => {
    link.setAttribute('nonce', nonce)
  })

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

router.afterEach(async (to, from) => {
  if (to.name && from.name && to.query?.wentBack !== 'true') {
    useHistoryStore().pushHistoryPage(from)
  }
})

export default router
