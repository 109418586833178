<template lang="pug">
.notification(:class="{'has-message': notification?.message}")
  .icon
    success-icon(v-if="notification.type === 'success'")

  .content
    span.title {{ notification.title }}

    p.message(v-if="typeof notification?.message === 'string' && notification.message") {{ notification.message }}

</template>

<script>
import { defineComponent } from 'vue'
import SuccessIcon from '@/components/icon/SuccessIcon.vue'

export default defineComponent({
  name: 'ActionNotification',

  props: {
    notification: {
      type: Object,
      required: true
    }
  },

  components: {
    SuccessIcon
  },

  setup() {

  }
})
</script>
