import auth from '@/router/middlewares/auth'
import guest from '@/router/middlewares/guest'
import valid2Fa from '@/router/middlewares/valid2Fa'
import contragent from '@/router/middlewares/contragent'
import admins from '@/router/middlewares/admins'
import users from '@/router/middlewares/users'
import operators from '@/router/middlewares/operators'
import logoutAuth from '@/router/middlewares/logoutAuth'

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/view/auth/LoginView.vue'),
    meta: {
      layout: 'auth',
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/login/2fa',
    name: 'login-2fa',
    component: () => import('@/view/auth/2FAView.vue'),
    meta: {
      layout: 'auth',
      middleware: [
        valid2Fa
      ]
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/view/auth/RegisterView.vue'),
    meta: {
      layout: 'auth',
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/reset-password/:string',
    name: 'reset-password',
    component: () => import('@/view/auth/ResetPasswordView.vue'),
    meta: {
      layout: 'auth',
      middleware: [
        logoutAuth
        // guest
      ]
    }
  },
  {
    path: '/register/success',
    name: 'register-success',
    component: () => import('@/view/auth/RegisterSuccessView.vue'),
    meta: {
      layout: 'auth',
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/view/auth/RemindPassword.vue'),
    meta: {
      layout: 'auth',
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/forgot-password/success',
    name: 'forgot-password-success',
    component: () => import('@/view/auth/ResetSuccessView.vue'),
    meta: {
      layout: 'auth',
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/select-impersonation',
    name: 'select-impersonation',
    component: () => import('@/view/Contragent/SelectImpersonation.vue'),
    meta: {
      layout: 'empty',
      middleware: [
        auth,
        operators
      ]
    }
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/view/HomeView.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent
      ],
      menu: 'products'
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/view/CartView.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent
      ],
      menu: 'products'
    }
  },
  {
    path: '/thank-you',
    name: 'thank-you',
    component: () => import('@/view/order/ThankYouView.vue'),
    meta: {
      layout: 'fullwidth',
      middleware: [
        auth,
        contragent
      ]
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/view/order/OrdersView.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent
      ],
      menu: 'products'
    }
  },
  {
    path: '/orders/:id',
    name: 'order',
    component: () => import('@/view/order/OrderView.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent
      ],
      menu: 'products'
    }
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/view/InvoicesView.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent
      ],
      menu: 'products'
    }
  },
  {
    path: '/messages',
    name: 'messages',
    // TODO: change component
    component: () => import('@/view/messages/MessagesView.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent
      ],
      menu: 'products'
    }
  },
  {
    path: '/message/:id',
    name: 'message',
    // TODO: change component
    component: () => import('@/view/messages/MessageView.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent
      ],
      menu: 'products'
    }
  },
  {
    path: '/favorite-products',
    name: 'favorite-products',
    component: () => import('@/view/FavoriteProductsView.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent
      ],
      menu: 'products'
    }
  },
  {
    path: '/contract-products',
    name: 'contract-products',
    component: () => import('@/view/ContractProductsView.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent
      ],
      menu: 'products'
    }
  },
  {
    path: '/templates',
    name: 'templates',
    component: () => import('@/view/template/TemplatesView.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent
      ],
      menu: 'products'
    }
  },
  {
    path: '/templates/:id',
    name: 'template',
    component: () => import('@/view/template/TemplateView.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent
      ],
      menu: 'products'
    }
  },
  {
    path: '/products/:id',
    name: 'product',
    component: () => import('@/view/ProductView.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent
      ],
      menu: 'products'
    }
  },
  {
    path: '/categories/:category1',
    name: 'category',
    component: () => import('@/view/CategoryView.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent
      ],
      menu: 'products'
    }
  },
  {
    path: '/settings/personal',
    name: 'settings-personal',
    component: () => import('@/view/settings/Personal.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth
      ],
      menu: 'settings'
    }
  },
  {
    path: '/settings/companies',
    name: 'settings-companies',
    component: () => import('@/view/settings/Companies.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent,
        users
        // admins
      ],
      menu: 'settings'
    }
  },
  {
    path: '/settings/companies/create',
    name: 'create-company',
    component: () => import('@/view/settings/CompanyCreate.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent,
        users
        // admins
      ],
      menu: 'settings'
    }
  },
  {
    path: '/settings/companies/:id',
    name: 'update-company',
    component: () => import('@/view/settings/CompanyUpdate.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent,
        users
        // admins
      ],
      menu: 'settings'
    }
  },
  {
    path: '/settings/companies/:contragentId/addresses/create',
    name: 'create-address',
    component: () => import('@/view/settings/AddressCreate.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent,
        users
        // admins
      ],
      menu: 'settings'
    }
  },
  {
    path: '/settings/companies/:contragentId/addresses/:id',
    name: 'update-address',
    component: () => import('@/view/settings/AddressUpdate.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent,
        users
        // admins
      ],
      menu: 'settings'
    }
  },
  {
    path: '/settings/employees',
    name: 'settings-employees',
    component: () => import('@/view/settings/Employees.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent,
        users
        // admins
      ],
      menu: 'settings'
    }
  },
  {
    path: '/settings/employees/create',
    name: 'create-employee',
    component: () => import('@/view/settings/EmployeeCreate.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent,
        users
        // admins
      ],
      menu: 'settings'
    }
  },
  {
    path: '/settings/employees/:id',
    name: 'update-employee',
    component: () => import('@/view/settings/EmployeeUpdate.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent,
        users
        // admins
      ],
      menu: 'settings'
    }
  },
  {
    path: '/settings/operators',
    name: 'settings-operators',
    component: () => import('@/view/settings/Operators.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent,
        operators,
        admins
      ],
      menu: 'settings'
    }
  },
  {
    path: '/settings/operators/create',
    name: 'create-operator',
    component: () => import('@/view/settings/OperatorCreate.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent,
        operators,
        admins
      ],
      menu: 'settings'
    }
  },
  {
    path: '/settings/operators/:id',
    name: 'update-operator',
    component: () => import('@/view/settings/OperatorUpdate.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        contragent,
        operators,
        admins
      ],
      menu: 'settings'
    }
  },
  {
    path: '/settings/advertisement',
    name: 'settings-advertisement',
    component: () => import('@/view/settings/Advertisement.vue'),
    redirect: '/settings/advertisement/general-banner',
    meta: {
      layout: 'main',
      middleware: [
        auth,
        operators,
        admins
      ],
      menu: 'settings'
    }
  },
  {
    path: '/settings/advertisement/general-banner',
    name: 'settings-advertisement-general-banner',
    component: () => import('@/view/settings/advertisement/GeneralBanner.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        operators,
        admins
      ],
      menu: 'settings'
    }
  },
  {
    path: '/settings/advertisement/suggested-items',
    name: 'settings-advertisement-suggested-items',
    component: () => import('@/view/settings/advertisement/SuggestedItems.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        operators,
        admins
      ],
      menu: 'settings'
    }
  },
  {
    path: '/settings/advertisement/group-pop-up',
    name: 'settings-advertisement-group-pop-up',
    component: () => import('@/view/settings/advertisement/GroupPopUp.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        operators,
        admins
      ],
      menu: 'settings'
    }
  },
  {
    path: '/settings/advertisement/marked-item',
    name: 'settings-advertisement-marked-item',
    component: () => import('@/view/settings/advertisement/MarkedItem.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        operators,
        admins
      ],
      menu: 'settings'
    }
  },
  {
    path: '/settings/advertisement/:slug/:id',
    name: 'settings-advertisement-detail',
    component: () => import('@/view/settings/advertisement/Detail.vue'),
    meta: {
      layout: 'main',
      middleware: [
        auth,
        operators,
        admins
      ],
      menu: 'settings'
    }
  },
  {
    path: '/rules',
    name: 'rules',
    component: () => import('@/view/rule/RuleView.vue'),
    meta: {
      layout: 'empty',
      middleware: [
        auth
      ],
      menu: ''
    }
  }
]
