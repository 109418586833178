<template lang="pug">
.mobile-menu-options
  ul
    li(
      v-for="(option, index) of options"
      :key="index"
      :class="{active: activeKey === index}"
      @click="$emit('click', option)"
    )
      slot(v-bind="option")

</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MobileMenuOptions',

  props: {
    options: {
      type: Array,
      required: true
    },

    activeKey: {
      type: [Number, String],
      default: null
    }
  },

  emits: ['click']
})
</script>
