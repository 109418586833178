import { get, post } from '@/plugins/httpService'
import preloader from '@/stores/base/preloader/actions'
import { setUrlParameter } from '@/helpers/url'
import actionTimeoutEnum from '@/enums/actionTimeoutEnum'
import form from '@/composables/form'

let timeout = null

let updateProductQuantityTimeout = null

export default {
  ...preloader,

  preload() {
    // this.fetchList()
  },

  fetchList() {
    this.isError = false

    post('/cart/templates', {
      searchTerm: this.getSearchTerm || undefined,
      page: this.getPage,
      perPage: this.getPerPage
    })
      .then(({ data }) => {
        this.list = data
      })
      .catch(() => {
        this.isError = true
      })
      .finally(() => {
        this.wasFetched = true
      })
  },

  setPage(page, fetch = true, changeUrl = true) {
    this.page = page

    if (changeUrl) {
      setUrlParameter('templates-page', this.page)
    }

    if (fetch) {
      this.fetchList()
    }
  },

  setPages(pages) {
    this.pages = pages
  },

  setPerPage(perPage) {
    if (![25, 50, 75, 100].includes(perPage)) {
      return
    }

    this.perPage = perPage

    setUrlParameter('templates-per-page', this.perPage)

    this.fetchList()
  },

  setSearchTerm(term, immediate = false) {
    this.searchTerm = term.trim()

    clearTimeout(timeout)

    timeout = setTimeout(() => {
      this.fetchList()

      setUrlParameter('templates-search', this.searchTerm)
    }, !immediate ? actionTimeoutEnum.search : 0)
  },

  destroy(RECID) {
    post('/cart/templates/destroy', { RECID })
      .then(({ data }) => {
        const index = this.list.findIndex((template) => template.RECID === data.RECID)

        if (this.list[index]) {
          this.list.splice(index, 1)
        }
      })
  },

  fetchInnerPageProducts(templateId) {
    this.innerPage.products.isFetching = true

    post('/cart/templates/products', {
      TemplateId: templateId * 1
    })
      .then(({ data }) => {
        this.innerPage.products.list = data
      })
      .finally(() => {
        this.innerPage.products.isFetching = false
      })
  },

  resetInnerPageProducts() {
    this.innerPage.products.list = []
    this.innerPage.products.isFetching = false
  },

  fetchInnerPage(id) {
    this.innerPage.isFetching = true

    const product = this.getList.find((item) => item.id === id)
    if (product) {
      this.setInnerPageData(product)
      this.innerPage.isFetching = false
      return
    }

    get(`/cart/templates/${id}`)
      .then(({ data }) => {
        this.setInnerPageData(data)

        if (data.RECID) {
          this.fetchInnerPageProducts(data.RECID)
        }
      })
      .catch(() => {
        this.setInnerPageData(null)
      })
      .finally(() => {
        this.innerPage.isFetching = false
      })
  },

  setInnerPageData(innerPage) {
    this.innerPage.data = innerPage
  },

  afterTemplateUpdate({ data }) {
    if (this.innerPage.data.RECID === data.RECID) {
      this.innerPage.data = data
    }

    const index = this.list.findIndex(({ RECID }) => RECID === data.RECID)

    if (index >= 0) {
      this.list[index] = data
    }

    form().onInputValue('templateUpdate', 'RECID', data.RECID)
    form().onInputValue('templateUpdate', 'Caption', data.Caption)
  },

  removeProduct(template, product) {
    post('/cart/templates/products/destroy', {
      templateId: template.RECID,
      productId: product.id
    })
      .then(({ data }) => {
        const index = this.innerPage.products.list.findIndex((item) => item.id === data.id)

        if (index >= 0) {
          this.innerPage.products.list.splice(index, 1)
        }
      })
  },

  updateProductQuantity(template, product, quantity) {
    clearTimeout(updateProductQuantityTimeout)

    updateProductQuantityTimeout = setTimeout(() => {
      post('/cart/templates/products/update', {
        TemplateId: template.RECID,
        ItemId: product.id,
        Quantity: quantity
      }).then(({ data }) => {
        const index = this.innerPage.products.list.findIndex((item) => item.id === data.id)

        if (this.innerPage.products.list[index]) {
          this.innerPage.products.list[index] = data
        }
      })
    }, actionTimeoutEnum.product.quantity)
  }
}
