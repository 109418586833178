import { getUrlParameter } from '@/helpers/url'

export default () => ({
  list: [],
  isError: false,
  wasFetched: false,
  page: (getUrlParameter('invoices-page') ?? 1) * 1,
  pages: 1,
  perPage: getUrlParameter('invoices-per-page') ? getUrlParameter('invoices-per-page') * 1 : 25,
  searchTerm: getUrlParameter('invoices-search') || ''
})
