import { computed, ref, watch } from 'vue'
import localStorageEnum from '@/enums/localStorageEnum'
import {useProductStore} from '@stores'

export default () => {
  const pricesWithTaxes = ref(localStorage.getItem(localStorageEnum.show_price_tax_key) === '1')

  const showPriceWithTaxes = computed(() => pricesWithTaxes)

  const productStore = useProductStore()

  const setPricesWithTaxes = (status) => {
    pricesWithTaxes.value = !!status

    productStore.fetchList()
  }

  watch(pricesWithTaxes, () => {
    localStorage.setItem(localStorageEnum.show_price_tax_key, pricesWithTaxes.value ? '1' : '0')
  })

  return {
    showPriceWithTaxes,
    setPricesWithTaxes
  }
}
