import { get, post } from '@/plugins/httpService'
import preloader from '@/stores/base/preloader/actions'
import router from '@/router'
import { localizeRouteName } from '@/helpers/route'
import {useAuthUserStore, useNotificationStore} from '@stores'
import form from '@/composables/form'

const RESOURCE = 'employees'

export default {
  ...preloader,

  fetchEmployees() {
    get(`/${RESOURCE}`)
      .then(({ data }) => {
        this.employees = data
        this.employeesFetched = true
      })
  },

  destroy(RECID) {
    post(`/${RESOURCE}/destroy`, { RECID })
      .then(({ data }) => {
        const index = this.employees.findIndex((employee) => employee.RECID === data.RECID)

        if (this.employees[index]) {
          this.employees.splice(index, 1)
        }
      })
  },

  afterEmployeeStore({ data: employee }) {
    router.push({ name: localizeRouteName('settings-employees') })

    useNotificationStore().push({
      type: 'success',
      title: 'settings.employees.stored_short',
      message: 'settings.employees.stored',
      translated: false
    })
  },

  afterEmployeeUpdate({ data: employee }) {
    router.push({ name: localizeRouteName('settings-employees') })

    const index = this.employees.findIndex(({ RECID }) => employee.RECID === RECID)

    if (this.employees[index]) {
      this.employees[index] = employee
    }

    const authUserStore = useAuthUserStore()
    const authUser = authUserStore.getAuth

    if (authUser.Model === 'User' && authUser.RECID === employee.RECID) {
      authUserStore.setAuthenticated(employee)
    }

    useNotificationStore().push({
      type: 'success',
      title: 'settings.employees.updated_short',
      message: 'settings.employees.updated',
      translated: false
    })
  },

  afterEmployeePasswordChange() {
    form().onInputValue('employee-change-password', ['User', 'Password'], '')

    useNotificationStore().push({
      type: 'success',
      title: 'notifications.settings.password_saved',
      translated: false
    })
  },

  async rights(userId) {
    const { data } = await post('/employees/rights', {
      User: {
        RECID: userId || null
      }
    })

    return data
  }
}
