export async function debugPinia({ store }) {
  if (import.meta.env.VITE_DEBUG_MODE === 'false') {
    return
  }

  const storeId = store.$id
  console.log(`%cPinia changes: ${storeId}`, 'color: green', {
    state: store.$state
  })
}
