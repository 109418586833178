<template lang="pug">
nav
  sidebar-sub-menu(
    v-if="menu?.length"
    :menu="menu"
    :depth="1"
    :is-products="isProducts"
  )

</template>

<script>
import { defineComponent } from 'vue'
import SidebarSubMenu from '@/components/sidebar/main/menu/SidebarSubMenu.vue'
import SidebarMenuItem from '@/components/sidebar/main/menu/SidebarMenuItem.vue'

export default defineComponent({
  name: 'SidebarMenu',

  components: {
    SidebarMenuItem,
    SidebarSubMenu
  },

  props: {
    menu: {
      type: Array,
      required: true
    },

    isProducts: {
      type: Boolean,
      default: false
    }
  }
})
</script>
