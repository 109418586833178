import Echo from 'laravel-echo'
import PusherJS from 'pusher-js'

window.Pusher = PusherJS

export default class Websocket {
  static init() {
    console.log('START INIT WEBSOCKETS')
    window.Echo = new Echo({
      broadcaster: 'pusher',
      cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
      key: import.meta.env.VITE_APP_PUSHER_APP_KEY,
      wsHost: import.meta.env.VITE_APP_PUSHER_APP_HOST,
      wsPort: import.meta.env.VITE_APP_PUSHER_APP_PORT,
      forceTLS: import.meta.env.VITE_APP_PUSHER_TLS === 'true',
      disableStats: true,
      namespace: 'App.Domain.Events',
      authEndpoint: '/broadcasting/auth'
    })

    console.log('INIT:::', window.Echo)

    this.listeningGlobal()
  }

  static listeningGlobal() {
    window.Echo.channel('global-channel')
      .listen('TestEvent', (event) => {
        console.log('WEBSOCKET EVENT:::', event)
      })
  }
}
