import { localizeRouteName } from '@/helpers/route'

export default function operators({ next, authUserStore, locale }) {
  if (authUserStore.getAuth?.Model !== 'Operator') {
    return next({
      name: localizeRouteName('home')
    })
  }

  if (!authUserStore.is2FAValid) {
    return next({
      name: `login-2fa.${locale}`,
      query: { redirect: window.location.pathname }
    })
  }

  return next()
}
