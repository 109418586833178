<template lang="pug">
figure.icon-wrapper
  slot

</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IconWrapper'
})
</script>
