import { getUrlParameter } from '@/helpers/url'

export default () => ({
  list: [],
  isError: false,
  wasFetched: false,

  selectedItem: null,

  page: (getUrlParameter('page') ?? 1) * 1,
  pages: 1,
  perPage: getUrlParameter('per-page') ? getUrlParameter('per-page') * 1 : 25,
  searchTerm: getUrlParameter('search') || ''
})
