import router from '@/router'

export function getQueryParameters() {
  const query = {}

  window.location.search.substring(1).split('&').forEach((pair) => {
    const [key, value] = pair.split('=')

    if (key) {
      const decoded = decodeURIComponent(value)

      if (!['null', 'undefined'].includes(decoded)) {
        query[key] = decoded
      }
    }
  })

  return query
}

export function getUrlParameter(key) {
  const parameter = getQueryParameters()[key]

  return typeof parameter === 'string' ? decodeURIComponent(parameter) : undefined
}

export function setUrlParameter(key, value) {
  router.push({ query: { ...getQueryParameters(), [key]: encodeURIComponent(value) } })
}
