<template lang="pug">
mobile-menu-container(
  :title="menuTitleTranslationKey"
  @close="close()"
  :data-menu="showMenu"
)
  template(v-slot:header)
    tax-toggler(v-if="'products' === showMenu")

  template(v-slot:default)
    template(v-if="showMenu === 'products'")
      search-input

      products-menu

    settings-menu(v-else-if="showMenu === 'settings'")

    language-menu(v-else-if="showMenu === 'languages'")

    contragent-menu(v-else-if="showMenu === 'companies'")

</template>

<script>
import {computed, defineComponent} from 'vue'
import { useMenuStore } from '@stores'
import MobileMenuContainer from '@/components/menu/mobile/MobileMenuContainer.vue'
import TaxToggler from '@/components/global/TaxToggler.vue'
import ProductsMenu from '@/components/menu/ProductsMenu.vue'
import SearchInput from '@/components/product/filter/ProductSearchInput.vue'
import SettingsMenu from '@/components/menu/SettingsMenu.vue'
import LanguageMenu from '@/components/menu/mobile/LanguageMenu.vue'
import ContragentMenu from '@/components/menu/mobile/ContragentMenu.vue'

export default defineComponent({
  name: 'MobileMenu',

  components: {
    ContragentMenu,
    LanguageMenu,
    SettingsMenu,
    TaxToggler,
    MobileMenuContainer,
    SearchInput,
    ProductsMenu
  },

  setup() {
    const { getMenuOpen: showMenu, close } = useMenuStore()

    const menuTitleTranslationKey = computed(() => {
      switch (showMenu.value) {
        case 'settings':
        case 'languages':
        case 'companies':
          return `menu.${showMenu.value}`

        default:
          return 'menu.menu'
      }
    })

    return {
      showMenu,
      close,
      menuTitleTranslationKey
    }
  }
})
</script>
