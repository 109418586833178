<template lang="pug">
search-input(
  :placeholder="`${$t('products.search')}...`"
  :model-value="searchTerm"
  @input="setSearchTerm($event)"
  @clear="setSearchTerm('', true)"
)
</template>

<script>
import {computed, defineComponent} from 'vue'
import SearchInput from '@/components/form/input/SearchInput.vue'
import { useProductStore } from '@/stores'

export default defineComponent({
  name: 'ProductSearchInput',

  components: {
    SearchInput
  },

  setup() {
    const productStore = useProductStore()

    const searchTerm = computed(() => productStore.getSearchTerm)

    function setSearchTerm(value, immediate = false) {
      productStore.setJustSearched(true)

      productStore.setSearchTerm(value, immediate)
    }

    return {
      searchTerm,
      setSearchTerm
    }
  }
})
</script>
