export const addToAdvertisement = (advId) => {
  const currentDate = new Date()
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }

  // Add 1 day to the current date
  const nextDay = new Date(currentDate)
  nextDay.setDate(currentDate.getDate() + 1)

  const formattedDateTime = nextDay.toLocaleDateString(undefined, options)

  const advObject = {
    id: advId,
    expire: formattedDateTime
  }

  const advertisements = JSON.parse(localStorage.getItem('advertisements')) || []

  // check if exist in localstorage
  const findIndexById = (id) => advertisements.findIndex((advertisement) => advertisement.id === id)

  const existingIndex = findIndexById(advId)

  if (existingIndex !== -1) {
    advertisements[existingIndex] = advObject
  } else {
    advertisements.push(advObject)
  }

  localStorage.setItem('advertisements', JSON.stringify(advertisements))
}

export const setNotExpiredAdvertisements = () => {
  let advertisements = JSON.parse(localStorage.getItem('advertisements')) || []

  const currentDate = new Date()
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }
  const formattedDateTime = currentDate.toLocaleDateString(undefined, options)

  if (advertisements.length <= 0) {
    return []
  }

  advertisements = advertisements.filter((adv) => formattedDateTime < adv.expire)

  localStorage.setItem('advertisements', JSON.stringify(advertisements))

  return advertisements
}

export const checkIfExist = (advId) => {
  const advertisements = JSON.parse(localStorage.getItem('advertisements')) || []

  // check if exist in localstorage
  const findIndexById = (id) => advertisements.findIndex((advertisement) => advertisement.id === id)

  const existingIndex = findIndexById(advId)

  return existingIndex !== -1
}
