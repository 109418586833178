import { defineStore } from 'pinia'
import AuthUserStore from './modelStore/authUser'
import ProductStore from './modelStore/product'
import DisplayGroupStore from './modelStore/displayGroup'
import AdvertisementStore from './modelStore/advertisement'
import FavoriteProductStore from './modelStore/favorite'
import CartStore from './modelStore/cart'
import TemplateStore from './modelStore/template'
import OrderStore from './modelStore/order'
import InvoiceStore from './modelStore/invoice'
import LanguageStore from './systemStore/language'
import FormStore from './systemStore/form'
import PriceStore from './systemStore/price'
import MenuStore from './systemStore/menu'
import HistoryStore from './systemStore/history'
import ContragentStore from './modelStore/contraget'
import ImpersonationStore from './modelStore/impersonation'
import AddressStore from './modelStore/address'
import CountryStore from './systemStore/location/country'
import NotificationStore from './systemStore/notification'
import MessageStore from './modelStore/message'
import UserStore from './modelStore/user'
import ModalStore from './systemStore/modal'
import OperatorStore from './modelStore/operator'
import RuleStore from './modelStore/rule'

export const useAuthUserStore = defineStore('authUserStore', AuthUserStore)
export const useLanguageStore = defineStore('languageStore', LanguageStore)
export const useFormStore = defineStore('formStore', FormStore)
export const usePriceStore = defineStore('priceStore', PriceStore)
export const useMenuStore = defineStore('menuStore', MenuStore)
export const useProductStore = defineStore('productStore', ProductStore)
export const useDisplayGroupStore = defineStore('displayGroupStore', DisplayGroupStore)
export const useAdvertisementStore = defineStore('advertisementStore', AdvertisementStore)
export const useHistoryStore = defineStore('historyStore', HistoryStore)
export const useFavoriteProductStore = defineStore('favoriteProductStore', FavoriteProductStore)
export const useCartStore = defineStore('cartStore', CartStore)
export const useContragentStore = defineStore('contragentStore', ContragentStore)
export const useTemplateStore = defineStore('templateStore', TemplateStore)
export const useOrderStore = defineStore('orderStore', OrderStore)
export const useImpersonationStore = defineStore('impersonationStore', ImpersonationStore)
export const useAddressStore = defineStore('addressStore', AddressStore)
export const useCountryStore = defineStore('countryStore', CountryStore)
export const useInvoiceStore = defineStore('invoiceStore', InvoiceStore)
export const useNotificationStore = defineStore('notificationStore', NotificationStore)
export const useMessageStore = defineStore('messageStoreStore', MessageStore)
export const useUserStore = defineStore('userStore', UserStore)
export const useModalStore = defineStore('modalStore', ModalStore)
export const useOperatorStore = defineStore('operatorStore', OperatorStore)
export const useRuleStore = defineStore('ruleStore', RuleStore)
