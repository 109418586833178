import { computed, ref } from 'vue'
import { useCartStore, useContragentStore } from '@stores'
import { get, post } from '@/plugins/httpService'
import addressTypeEnum from '@/enums/addressTypeEnum'

export default () => {
  const contragentStore = useContragentStore()
  const cartStore = useCartStore()

  const list = ref([])
  const isError = ref(false)
  const wasFetched = ref(false)

  const getList = computed(() => list)

  const getIsError = computed(() => isError)

  const getWasFetched = computed(() => wasFetched)

  const getOptions = computed(() => {
    const contragent = contragentStore.getSelectedItem

    if (!contragent) {
      return []
    }

    const contragentAddresses = list.value.filter((address) => {
      return address.ContragentId === contragent.RECID && address.AddressType === addressTypeEnum.delivery_address && address.AddressConfirmed === 1 && address.Active === 1
    })

    return contragentAddresses.map((address) => ({
      title: [contragent.CompanyName, address.AddressLine, address.City].join(', '),
      value: address.RECID
    }))
  })

  const getCartBuyerAddress = computed(() => {
    const buyer = cartStore.getCartBuyer

    if (!buyer) {
      return null
    }

    return list.value.find((address) => {
      return address.ContragentId === buyer.RECID
    })
  })

  const getCartPayerAddress = computed(() => {
    const payer = cartStore.getCartPayer

    if (!payer) {
      return null
    }

    return list.value.find((address) => {
      return address.ContragentId === payer.RECID
    })
  })

  function fetchList() {
    isError.value = false

    get('/addresses')
      .then(({ data }) => {
        list.value = data
        wasFetched.value = true
      })
      .catch(() => {
        isError.value = true
      })
  }

  function preload() {
    fetchList()
  }

  function destroy(RECID) {
    post('/contragents/addresses/destroy', { RECID })
      .then(({ data }) => {
        contragentStore.destroyAddress(data)
      })
  }

  return {
    list,
    getList,
    getIsError,
    getWasFetched,
    getOptions,
    getCartBuyerAddress,
    getCartPayerAddress,
    preload,
    fetchList,
    destroy
  }
}
