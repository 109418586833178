import { useModalStore } from '@stores'

export default function useModal() {
  const modalStore = useModalStore()

  function show(modal) {
    modalStore.show(modal)
  }

  function hide() {
    modalStore.hide()
  }

  return {
    show,
    hide
  }
}
