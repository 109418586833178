import {
  useImpersonationStore,
  useAuthUserStore,
  useCartStore,
  useProductStore,
  useInvoiceStore,
  useOrderStore,
  useNotificationStore
} from '@/stores'
import { post } from '@/plugins/httpService'
import router from '@/router'
import { localizeRouteName } from '@/helpers/route'

const RESOURCE = 'contragents'

export default {
  setSelectedItem(item) {
    const cartStore = useCartStore()
    const productStore = useProductStore()
    const invoiceStore = useInvoiceStore()
    const orderStore = useOrderStore()

    this.selectedItem = item
    sessionStorage.setItem('selectedContragent', item.RECID)

    const authUserStore = useAuthUserStore()

    const promise = authUserStore.getUserType === 'User'
      ? post(`/${RESOURCE}/select`, { User: { LastContragentId: item.RECID } })
      : Promise.resolve()

    promise
      .then(() => {
        if (this.wasSelected) {
          if (cartStore.getWasFetched) {
            cartStore.fetchCart()
          }

          cartStore.fetchCartProducts()

          if (productStore.getWasFetched) {
            productStore.fetchList()
          }

          if (productStore.getWasFetchedContracts) {
            productStore.fetchContracts()
          }

          if (orderStore.getWasFetched) {
            orderStore.fetchList()
          }

          if (invoiceStore.getWasFetched) {
            invoiceStore.fetchList()
          }
        }
      })
      .finally(() => {
        this.wasSelected = true
      })
  },

  setContragentList(data) {
    this.list = data
  },

  fetchSelectedItems(impersonationId, contragentId) {
    const impersonationStore = useImpersonationStore()
    impersonationStore.setSelectedItem(impersonationId, contragentId)
  },

  afterContragentUpdate({ data }) {
    const index = this.list.findIndex((item) => item.RECID === data.RECID)

    if (index >= 0) {
      this.list[index] = data
    }

    useNotificationStore().push({
      type: 'success',
      title: 'settings.contragents.updated_short',
      message: 'settings.contragents.updated',
      translated: false
    })

    router.push({ name: localizeRouteName('settings-companies') })
  },

  afterContragentStore({ data }) {
    useNotificationStore().push({
      type: 'success',
      title: 'settings.contragents.stored_short',
      message: 'settings.contragents.stored',
      translated: false
    })

    router.push({ name: localizeRouteName('settings-companies') })
  },

  destroy(RECID) {
    post(`/${RESOURCE}/destroy/`, { RECID })
      .then(({ data }) => {
        const index = this.list.findIndex((item) => item.RECID === data.RECID)

        if (index >= 0) {
          this.list.splice(index, 1)
        }
      })
  },

  afterContragentAddressStore({ data }) {
    const contragentIndex = this.list.findIndex(({ RECID }) => RECID === data.ContragentId)

    if (contragentIndex >= 0) {
      this.list[contragentIndex].DeliveryAddresses.push(data)
    }

    router.push({ name: localizeRouteName('update-company'), params: { id: router.currentRoute.value.params.contragentId } })

    useNotificationStore().push({
      type: 'success',
      title: 'contragents.updated_short',
      message: 'contragents.updated',
      translated: false
    })
  },

  afterContragentAddressUpdate({ data }) {
    const contragentIndex = this.list.findIndex((contragent) => contragent.RECID === data.ContragentId)

    if (contragentIndex >= 0) {
      const addressIndex = this.list[contragentIndex].DeliveryAddresses.findIndex(({ RECID }) => RECID === data.RECID)

      if (addressIndex >= 0) {
        const oldData = this.list[contragentIndex].DeliveryAddresses[addressIndex]

        this.list[contragentIndex].DeliveryAddresses[addressIndex] = data

        const changes = ['AddressLine', 'ZIPCode', 'CountryId', 'City'].filter((key) => oldData[key] !== data[key])

        if (changes.length) {
          useNotificationStore().push({
            type: 'success',
            title: 'contragents.address.saved_short',
            message: 'contragents.address.saved',
            life_span: 7000,
            translated: false
          })
        }
      }
    }

    router.push({ name: localizeRouteName('update-company'), params: { id: router.currentRoute.value.params.contragentId } })
  },

  destroyAddress(address) {
    const contragentIndex = this.list.findIndex(({ RECID }) => RECID === address.ContragentId)

    if (contragentIndex >= 0) {
      const addressIndex = this.list[contragentIndex].DeliveryAddresses.findIndex(({ RECID }) => RECID === address.RECID)

      this.list[contragentIndex].DeliveryAddresses.splice(addressIndex, 1)
    }
  }
}
