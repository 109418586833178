import { isEqual } from 'lodash'
import { ref, computed } from 'vue'

export default () => {
  const histories = ref([])

  const getHistories = computed(() => histories)

  function pushHistoryPage(page) {
    const lastHistory = histories.value[histories.value.length - 1]

    if (
      lastHistory
      && lastHistory.name === page.name
      && Object.keys(page.params).length
      && isEqual(page.params, lastHistory.params)
    ) {
      return
    }

    const parameters = {}

    Object.keys(page.params).forEach((key) => {
      parameters[key] = page.params[key]
    })

    if (page) {
      histories.value = [
        ...(Array.isArray(histories.value) ? histories.value : []),
        { name: page.name, params: parameters }
      ]
    }
  }

  function popHistoryPage() {
    if (histories.value.length) {
      histories.value.pop()
    }
  }

  return {
    histories,
    getHistories,
    pushHistoryPage,
    popHistoryPage
  }
}
