<template lang="pug">
sidebar-menu.products-menu.user-products-menu(:menu="secondaryMenu")

sidebar-menu.products-menu.shop-products-menu(
  :menu="displayGroups"
  :is-products="true"
)

</template>

<script>
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDisplayGroupStore } from '@stores'
import { localizeRouteName } from '@/helpers/route'
import HeartIcon from '@/components/icon/HeartIcon.vue'
import DocumentIcon from '@/components/icon/DocumentIcon.vue'
import EditIcon from '@/components/icon/EditIcon.vue'
import HomeIcon from '@/components/icon/HomeIcon.vue'
import SidebarMenu from '@/components/sidebar/main/menu/SidebarMenu.vue'

export default defineComponent({
  name: 'ProductsMenu',

  components: {
    SidebarMenu
  },

  setup() {
    const { t } = useI18n()
    const displayGroupStore = useDisplayGroupStore()

    const secondaryMenu = [
      {
        title: t('sidebar.menu.favorite-products'),
        icon: HeartIcon,
        route: { name: localizeRouteName('favorite-products') }
      },

      {
        title: t('sidebar.menu.contract-products'),
        icon: DocumentIcon,
        route: { name: localizeRouteName('contract-products') }
      },

      {
        title: t('sidebar.menu.templates'),
        icon: EditIcon,
        route: { name: localizeRouteName('templates') }
      }
    ]

    const displayGroups = computed(() => [
      {
        id: 0,
        title: t('home'),
        icon: HomeIcon,
        route: {
          name: localizeRouteName('home')
        }
      },
      ...displayGroupStore.getListTree
    ])

    return {
      secondaryMenu,
      displayGroups,
      localizeRouteName
    }
  }
})
</script>
