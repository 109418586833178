export default {
  isAuthenticated: (state) => Object.keys(state.authenticated).length,
  is2FAValid: (state) => (state.authenticated.Use2F ? state.authenticated.isValid : true),
  getAuth: (state) => state.authenticated,
  getFullName: (state) => state.authenticated?.NameSurname,
  getUserType: (state) => state.authenticated?.Model,
  isAdmin: (state) => !!state.authenticated?.Admin,
  getPhone: (state) => state.authenticated?.Phone,
  isPasswordResetSend: (state) => state.passwordResetMailSend,
}
