import { useI18n } from 'vue-i18n'
import { ref } from 'vue'

const NOTIFICATION_LIFE_SPAN_MS = 3000

/**
 * @typedef {Object} Notification
 * @property {number} id - The ID of the notification
 * @property {'success'|'warning'|'error'} type - The type of the notification
 * @property {string} title - The title of the notification
 * @property {?number} life_span - The lifespan of the notification in milliseconds
 * @property {boolean} translated - If the given notification title is translated
 */

export default () => {
  const notifications = ref([])
  const { t } = useI18n()

  function splice(id) {
    const index = notifications.value.findIndex((notification) => notification.id === id)

    if (notifications.value[index]) {
      notifications.value.splice(index, 1)
    }
  }

  function push(notification) {
    const id = Math.floor(Math.random() * 100)

    notification.id = id

    if (!notification.translated) {
      notification.title = t(notification.title)

      if (typeof notification.message === 'string') {
        notification.message = t(notification.message)
      }
    }

    notifications.value.unshift(notification)

    setTimeout(() => splice(id), notification.life_span ?? NOTIFICATION_LIFE_SPAN_MS)
  }

  return {
    notifications,
    push,
    splice
  }
}
