import { formatPrice } from '@/helpers/price'
import priceComposable from '@/composables/price'

export default {
  getList: (state) => state.list,
  getIsError: (state) => state.isError,
  getWasFetched: (state) => state.wasFetched,
  getPage: (state) => state.page,
  getPages: (state) => state.pages,
  getPerPage: (state) => {
    const options = [25, 50, 75, 100]

    return options.find((option) => option === state.perPage * 1) || 25
  },
  getSearchTerm: (state) => state.searchTerm,
  getProducts: (state) => state.innerPage.products.list,
  getProductsIsFetching: (state) => state.innerPage.products.isFetching,
  getInnerPageData: (state) => state.innerPage.data,
  isInnerPageFetching: (state) => state.innerPage.isFetching,
  getTemplateTotalPrice: (state) => {
    const { getProductPrice } = priceComposable()

    const price = state.innerPage.products.list.reduce((accumulator, product) => {
      return accumulator + getProductPrice(product, product.quantity)
    }, 0)

    return formatPrice(price)
  }
}
