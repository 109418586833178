export default async function guest({ next, authUserStore, locale }) {
  console.log('GUEST')
  let authenticated = false

  if (authUserStore.isAuthenticated) {
    return next({
      name: `home.${locale}`
    })
  }

  await authUserStore.authUser()
    .then(() => { authenticated = true })
    .catch(() => { authenticated = false })

  if (authenticated) {
    return next({
      name: `home.${locale}`
    })
  }

  return next()
}
