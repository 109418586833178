import { localizeRouteName } from '@/helpers/route'

export default function admins({
  next,
  authUserStore
}) {
  if (!authUserStore.getAuth?.Admin) {
    return next({
      name: localizeRouteName('home')
    })
  }

  return next()
}
