<template lang="pug">
.setting-menu
  .menu-title.m-b-30
    | {{ $t('settings.settings') }}

  secondary-button.m-b-40(
    v-if="userType === 'User'"
    @click="toRouteName('home')"
  )
    | {{ $t('settings.get_back_to_products') }}

  secondary-button.m-b-40(
    v-if="userType === 'Operator'"
    @click="toRouteName('select-impersonation')"
  )
    | {{ $t('settings.get_back_to_clients') }}

  sidebar-menu.products-menu.user-products-menu(:menu="menuLinks")

</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import SidebarMenu from '@/components/sidebar/main/menu/SidebarMenu.vue'
import { localizeRouteName, toRouteName } from '@/helpers/route'
import HeartIcon from '@/components/icon/HeartIcon.vue'
import DocumentIcon from '@/components/icon/DocumentIcon.vue'
import EditIcon from '@/components/icon/EditIcon.vue'
import { useAuthUserStore } from '@/stores'
import SecondaryButton from '@/components/form/button/SecondaryButton.vue'

export default defineComponent({
  name: 'SettingsMenu',

  components: {
    SecondaryButton,
    SidebarMenu
  },

  setup() {
    const { getUserType, isAdmin } = storeToRefs(useAuthUserStore())
    const { t } = useI18n()

    const operatorMenu = [
      {
        title: t('settings.personal'),
        icon: HeartIcon,
        route: { name: localizeRouteName('settings-personal') }
      }
    ]

    if (isAdmin.value) {
      operatorMenu.push(
        {
          title: t('settings.operators.operators'),
          icon: DocumentIcon,
          route: { name: localizeRouteName('settings-operators') }
        },
        {
          title: t('settings.advertisement'),
          icon: EditIcon,
          route: { name: localizeRouteName('settings-advertisement') },
          subMenu: [
            {
              id: 1,
              title: t('settings.general_banner'),
              route: { name: localizeRouteName('settings-advertisement-general-banner') }
            },{
              id: 2,
              title: t('settings.suggested_items'),
              route: { name: localizeRouteName('settings-advertisement-suggested-items') }
            },{
              id: 3,
              title: t('settings.marked_items'),
              route: { name: localizeRouteName('settings-advertisement-marked-item') }
            },{
              id: 4,
              title: t('settings.group_pop_up'),
              route: { name: localizeRouteName('settings-advertisement-group-pop-up') }
            }
          ]
        }
      )
    }

    const userMenu = [
      {
        title: t('settings.personal'),
        icon: HeartIcon,
        route: { name: localizeRouteName('settings-personal') }
      },
      {
        title: t('settings.companies'),
        icon: DocumentIcon,
        route: { name: localizeRouteName('settings-companies') }
      },

      {
        title: t('settings.employees.employees'),
        icon: EditIcon,
        route: { name: localizeRouteName('settings-employees') }
      }
    ]

    // id: item.id,
    //     title: item.caption,
    //     route: {
    //       name: localizeRouteName('category'),
    //       params: {
    //         category1: item.id
    //       }
    //     },
    //     subMenu: []

    return {
      menuLinks: (getUserType.value === 'User' ? userMenu : operatorMenu),
      userType: getUserType,
      toRouteName
    }
  }
})
</script>
