<template lang="pug">
mobile-menu-options(
  :options="locales"
  :active-key="currentLocaleIndex"
  @click="handleClick($event)"
)
  template(v-slot="{ locale }")
    img(:src="baseUrl + '/storage/flag/' + locale + '.png'")

    | {{ $t(`languages.${locale}`) }} {{ $t('language').toLowerCase() }}

</template>

<script>
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { toRouteName, getCurrentRouteName } from '@/helpers/route'
import MobileMenuOptions from '@/components/menu/mobile/MobileMenuOptions.vue'

export default defineComponent({
  name: 'LanguageMenu',

  components: {
    MobileMenuOptions
  },

  setup() {
    const baseUrl = window.location.origin

    const { locale: currentLocale, availableLocales } = useI18n()

    const locales = computed(() => availableLocales.map((locale) => ({ locale })))

    const currentLocaleIndex = computed(() => locales.value.findIndex(({ locale }) => locale === currentLocale.value))

    function handleClick({ locale }) {
      toRouteName(getCurrentRouteName(this.$route.name), locale, true)
    }

    return {
      baseUrl,
      currentLocale,
      currentLocaleIndex,
      locales,
      toRouteName,
      getCurrentRouteName,
      handleClick
    }
  }
})
</script>
