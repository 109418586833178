import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { localizeRouteName, unlocalizeRouteName } from '@/helpers/route'
import { get } from '@/plugins/httpService'

export default () => {
  const route = useRoute()

  const list = ref([])

  const categoriesProductsCount = ref({})

  const currentId = computed(() => (unlocalizeRouteName(route.name) === 'category' ? route.params.category1 * 1 : null))

  const getList = computed(() => list.value)

  function getDisplayGroup(id) {
    return getList.value.find((item) => item.id === id)
  }

  const getCategoriesProductsCount = computed(() => {
    const productsCount = {}

    for (let [key, value] of Object.entries(categoriesProductsCount.value)) {
      key *= 1
      value *= 1

      const category = getDisplayGroup(key)

      productsCount[key] = value + (productsCount?.[key] || 0)

      if (category?.parent_id) {
        let parent = getDisplayGroup(category?.parent_id)

        while (true) {
          if (!parent) {
            break
          }

          if (!Object.keys(productsCount).includes(parent.id.toString())) {
            productsCount[parent.id] = 0
          }

          productsCount[parent.id] = productsCount[parent.id] + value

          parent = getDisplayGroup(parent?.parent_id)
        }
      }
    }

    return productsCount
  })

  const getCurrentTree = computed(() => {
    const tree = []

    const currentCategory = currentId.value ? getDisplayGroup(currentId.value) : null

    if (!currentCategory) {
      return tree
    }

    tree.unshift(currentCategory)

    while (true) {
      const id = tree[0].parent_id

      const category = id ? getDisplayGroup(id) : null

      if (!category) {
        break
      }

      tree.unshift(category)
    }

    return tree
  })

  const getCurrentTreeIds = computed(() => getCurrentTree.value.map(({ id }) => id))

  const getListTree = computed(() => {
    const idToData = {}
    const tree = []

    list.value.forEach((item) => {
      idToData[item.id] = {
        id: item.id,
        title: item.caption,
        showAdvertisement: item.show_advertisements,
        route: {
          name: localizeRouteName('category'),
          params: {
            category1: item.id
          }
        },
        subMenu: []
      }
    })

    list.value.forEach((item) => {
      const node = idToData[item.id]

      if (item.parent_id === null) {
        tree.push(node)
      } else {
        const parent = idToData[item.parent_id]

        if (parent) {
          parent.subMenu.push(node)
        }
      }
    })

    return tree
  })

  const getBreadcrumbs = computed(() => {
    const find = (categoryId, hierarchy = []) => {
      const index = list.value.findIndex((category) => category.id === categoryId)

      const nextHierarchy = [
        ...(list.value[index] ? [list.value[index]] : []),
        ...hierarchy
      ]

      if (index >= 0 && list.value[index].parent_id !== null) {
        return find(list.value[index].parent_id, nextHierarchy)
      }

      return nextHierarchy
    }

    return (categoryId) => find(categoryId * 1)
      .map((category) => ({
        title: category.caption,
        route: {
          name: localizeRouteName('category'),
          params: {
            category1: category.id
          }
        }
      }))
  })

  function preload() {
    get('/display-groups')
      .then(({ data }) => {
        this.list = data
      })
  }

  function setCategoriesProductsCount(categories) {
    categoriesProductsCount.value = categories
  }

  function unsetCategoriesProductsCount() {
    setCategoriesProductsCount({})
  }

  return {
    currentId,
    categoriesProductsCount,
    list,
    getList,
    getListTree,
    getBreadcrumbs,
    getCurrentTree,
    getCurrentTreeIds,
    getCategoriesProductsCount,
    preload,
    setCategoriesProductsCount,
    unsetCategoriesProductsCount
  }
}
