export default () => ({
  cart: null,
  isFetching: false,
  wasFetched: false,
  isUpdating: false,
  products: {
    list: [],
    isFetching: false
  }
})
