export const locales = ['en', 'lt']
export const mainLocale = 'lt'

export function getLocale() {
  const pathArray = window.location.pathname.split('/')

  const lang = pathArray[1]

  return locales.includes(lang) ? lang : mainLocale
}

export function getRouteName(name, locale = '') {
  if (locale) {
    return `${name}.${locale}`
  }

  return `${name}.${getLocale()}`
}

export function getFullLocale() {
  switch (getLocale()) {
    case 'en':
      return 'en-US'

    case 'lt':
      return 'lt-LT'

    default:
      return null
  }
}

export function getLocaleId(locale) {
  switch (locale) {
    case 'lt':
      return 1
    case 'en':
      return 2
    default:
      return 1
  }
}
