<template lang="pug">

</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ErrorLayout'
})
</script>
